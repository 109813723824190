import AplicacoesCardsItem from "./CardItem";

export default function AplicacoesCardsSection({
  applications,
  server_time,
  setServerTime,
}) {
  return (
    <div
      style={{
        flexWrap: "wrap",
        gap: "2rem",
      }}
    >
      {applications.map((application, index) => (
        <AplicacoesCardsItem
          {...{
            application,
            server_time,
            setServerTime,
          }}
          key={index}
        />
      ))}
    </div>
  );
}
