import { Route, Routes } from "react-router-dom";
import AplicacoesTableView from "./AppView";
import AplicacoesResponderPage from "./Responder";
// import AplicacoesExamView from "./MaisDetalhes";

export default function AplicacoesPage() {
  return (
    <div
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        gap: "2rem",
      }}
    >
      <Routes>
        <Route path="responder/:id" element={<AplicacoesResponderPage />} />
        {/* <Route path="mais-detalhes/:id" element={<AplicacoesExamView />} /> */}
        <Route path="*" element={<AplicacoesTableView />} />
      </Routes>
    </div>
  );
}
