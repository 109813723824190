import CustomText from "components/CustomText";
import { getAlphabetLetterIndex } from "utilities/helpers";
import { indexToLetter, maskDifficulty } from "utilities/exams/maskDifficulty";
import { useState } from "react";
import ReviseQuestionItem from "./Revise";
import swal from "sweetalert";
import { put } from "utilities/requests";
import AnswerQuestionItem from "./Answer";
import { Box, Modal } from "@mui/material";
import { STORAGE_URL } from "utilities/variables";
import EventNoteIcon from "@mui/icons-material/EventNote";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Row } from "reactstrap";
import DrawAnnotations from "./DrawAnotations";
import CancelIcon from "@mui/icons-material/Cancel";

const QuestionProvaItem = ({ question, index, ActionButton }) => {
  // const difficulty_style = maskDifficulty(question.difficulty_level);

  const [new_user_message, setNewUserMessage] = useState("");
  const [is_responding, setIsResponding] = useState(false);
  const [open_anotations_modal, setOpenAnotationsModal] = useState(false);

  const handleSubmit = async () => {
    if (new_user_message.length < 10) {
      swal({
        title: "Atenção!",
        text: "O motivo da revisão deve ter ao menos 10 caracteres",
        icon: "warning",
        button: "Ok",
      });
      return;
    }

    const res = await put(
      `corrections/${question.corrections[0]._id}/claim`,
      JSON.stringify({
        message: new_user_message,
      })
      // JSON.stringify({
      //   questions: selectedQuestions.map((question) => ({
      //     id: question.corrections[0]._id,
      //     message: question.exam_review_request_message,
      //   })),
      // })
    );

    if (res?.httpCode === 200) {
      swal({
        title: "Sucesso!",
        text: "Revisão solicitada com sucesso!",
        icon: "success",
        button: "Ok",
      }).then(() => {
        setIsResponding(false);
        setNewUserMessage("");
        window.location.reload(true);
      });
    } else {
      swal({
        title: "Erro!",
        text: "Ocorreu um erro ao solicitar a revisão, tente novamente mais tarde",
        icon: "error",
        button: "Ok",
      });
    }
  };

  return (
    <Box
      sx={{
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "rgba(var(--accent-color1), 0.03)",
        border: "1px solid rgba(0,0,0,0.1)",
        padding: {
          xs: "0.6rem 0.8rem",
          sm: "1rem 1.4rem",
          md: "1.6rem 2rem",
        },
      }}
    >
      {question.not_answered && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            backgroundColor: "#faefd7",
            padding: "0.4rem 0.8rem",
            borderRadius: "0.4rem",
          }}
        >
          <InfoOutlinedIcon style={{ fontSize: "1.8rem" }} /> Você não respondeu
          essa questão
        </div>
      )}

      <CustomText
        variant="span"
        text={`${question.number_index}. <b style="font-weight: 600;">${question.question_content}</b>`}
        style={{
          fontSize: "1.1rem",
          textAlign: "justify",
          width: "100%",
        }}
      />

      <div
        style={{
          flexDirection: "column",
          gap: "0.4rem",
        }}
      >
        {question.type === "objective" &&
          question.alternatives.map((alternative, index) => {
            const correct = getAlphabetLetterIndex(question.correct);
            return (
              <div
                key={index}
                style={{
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    gap: "0.8rem",
                    width: "100%",
                    padding: "0.4rem",
                    borderRadius: "0.4rem",
                    //
                    backgroundColor:
                      alternative.checked && index === correct
                        ? "#e6f4ea"
                        : index === correct
                        ? "rgba(0, 140, 255, 0.15)"
                        : alternative.checked
                        ? "rgba(255, 0, 0, 0.15)"
                        : "transparent",
                  }}
                >
                  {index === correct && alternative.checked ? (
                    <div>
                      <CheckCircleRoundedIcon
                        style={{
                          color: "#2c9c3d",
                          fontSize: "1.8rem",
                        }}
                      />
                    </div>
                  ) : alternative.checked ? (
                    <CancelIcon
                      style={{
                        color: "#ff665c",
                        fontSize: "1.8rem",
                      }}
                    />
                  ) : (
                    <div>
                      <div
                        style={{
                          height: "1.5rem",
                          width: "1.5rem",
                          backgroundColor: "var(--background-color2)",
                          borderRadius: "50%",
                          border: "1px solid rgba(0,0,0,0.2)",
                          marginLeft: "0.2rem",
                        }}
                      />
                    </div>
                  )}

                  <CustomText
                    variant="span"
                    text={`<b>${indexToLetter(index)}) </b>${
                      alternative.alternative_content
                    }`}
                    style={{
                      width: "100%",
                      fontSize: "1rem",
                      textAlign: "justify",
                    }}
                  />
                </div>
                <span
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  {index === correct && !alternative.checked && (
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "0.9rem",
                      }}
                    >
                      Alternativa correta
                    </span>
                  )}
                  {index !== correct && alternative.checked && (
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "0.9rem",
                      }}
                    >
                      Você marcou
                    </span>
                  )}
                  {index === correct && alternative.checked && (
                    <span
                      style={{
                        fontWeight: 600,
                        fontSize: "0.9rem",
                      }}
                    >
                      Você acertou!
                    </span>
                  )}
                </span>
              </div>
            );
          })}

        {question.type === "text" && (
          <div
            style={{
              flexDirection: "column",
              gap: "0.4rem",
            }}
          >
            <CustomText
              variant="span"
              text={question.question_answer}
              style={{
                width: "100%",
                textAlign: "justify",
              }}
            />
            <span
              style={{
                fontWeight: 600,
                fontSize: "0.9rem",
              }}
            >
              Sua resposta:
            </span>

            {/* <img
              src={STORAGE_URL + question.dissertative_answer}
              style={{
                width: "100%",
                height: "auto",
                objectFit: "contain",
                borderRadius: "0.4rem",
                border: "1px solid rgba(0,0,0,0.2)",
              }}
              alt="Resposta do aluno"
            /> */}

            <CustomText
              variant="span"
              text={question.essay_answer}
              style={{
                width: "100%",
                fontSize: "1rem",
                textAlign: "justify",
                marginBottom: "1rem",
              }}
            />
          </div>
        )}

        {/* TODO: Diff degree */}
        {/* <div
          style={{
            padding: "0.4rem 0.8rem",
            backgroundColor: "var(--background-color2)",
            borderRadius: "0.4rem",
            gap: "0.6rem",
            alignItems: "center",
          }}
        >
          <CustomText
            text={`Dificuldade:`}
            style={{
              fontSize: "1rem",
              textAlign: "justify",
              fontWeight: 600,
            }}
          />
          <div
            style={{
              gap: "0.3rem",
              alignItems: "center",
            }}
          >
            <Icon
              component={difficulty_style.dif_icon}
              style={{
                color: difficulty_style.dif_color,
                fontSize: "1.8rem",
                backgroundColor: 'white',
                borderRadius: '50%',
              }}
            />
            <CustomText
              text={question.difficulty_level}
              style={{
                fontSize: "1rem",
                textAlign: "justify",
              }}
            />
          </div>
          <div
            style={{
              gap: "0.3rem",
              alignItems: "center",
            }}
          >
            <SchoolIcon
              style={{
                fontSize: "1.8rem",
              }}
            />
            <CustomText
              text={question.degree}
              style={{
                fontSize: "1rem",
                textAlign: "justify",
              }}
            />
          </div>
        </div> */}

        {/* TODO: ANSWER'S JUSTIFICATION */}
        <div
          style={{
            padding: "0.4rem 0.8rem",
            backgroundColor: "var(--background-color2)",
            borderRadius: "0.4rem",
            flexDirection: "column",
          }}
        >
          <CustomText
            variant="span"
            text={`Justificativa:`}
            style={{
              fontSize: "1rem",
              textAlign: "justify",
              width: "100%",
              fontWeight: 600,
            }}
          />
          <CustomText
            variant="span"
            text={question.justify_answer_text}
            style={{
              fontSize: "1rem",
              textAlign: "justify",
              width: "100%",
            }}
          />
        </div>

        <Modal
          open={open_anotations_modal}
          onClose={() => setOpenAnotationsModal(false)}
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              fontFamily: "Sans-serif",
              display: "flex",
              flexDirection: "column",
              maxHeight: "98vh",
              maxWidth: 1000,
              width: "100%",
              gap: "1rem",
              padding: "3rem 2rem",
            }}
          >
            <div>
              <CustomText
                variant="span"
                text={`Anotações da questão ${index + 1}`}
                style={{
                  fontSize: "1.4rem",
                  textAlign: "justify",
                  width: "100%",
                  fontWeight: 600,
                }}
              />

              <CloseIcon
                onClick={() => setOpenAnotationsModal(false)}
                style={{
                  position: "absolute",
                  top: "1rem",
                  right: "1rem",
                  cursor: "pointer",
                  fontSize: "2rem",
                }}
              />
            </div>
            {/* TODO: ANSWER'S JUSTIFICATION */}
            <div
              style={{
                padding: "0.8rem 0.8rem",
                backgroundColor: "var(--background-color2)",
                borderRadius: "0.4rem",
                flexDirection: "column",
                gap: "0.4rem",
              }}
            >
              <CustomText
                variant="span"
                text={`Justificativa:`}
                style={{
                  fontSize: "1rem",
                  textAlign: "justify",
                  width: "100%",
                  fontWeight: 600,
                }}
              />
              <CustomText
                variant="span"
                text={question.justify_answer_text}
                style={{
                  fontSize: "1rem",
                  textAlign: "justify",
                  width: "100%",
                }}
              />
            </div>

            {question.type === "text" && question.corrections && (
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: 400,
                  overflow: "hidden",
                  overflowX: "auto",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    zIndex: 10,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: 900,
                    height: 400,
                  }}
                />
                <DrawAnnotations
                  height={400}
                  width={900}
                  annotation_edit={null}
                  showEditAnnotation={() => {}}
                  annotations={question.corrections[0].annotations}
                  MouseEnter={() => {}}
                  new_anotation={null}
                  newAnnotation={(e) => {}}
                />
                <img
                  style={{
                    height: 350,
                    minWidth: 900,
                    maxWidth: 900,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                  src={STORAGE_URL + question.dissertative_answer}
                />
              </Row>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.8rem",
                padding: "0 0.8rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <InfoOutlinedIcon style={{ fontSize: "1.5rem" }} />
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "1.1rem",
                  }}
                >
                  Legendas
                </span>
              </div>
              {question.corrections !== undefined &&
                question.corrections[0]?.annotations !== undefined &&
                question.corrections[0]?.annotations.map(
                  (annotation, index) => (
                    <span key={index}>
                      <b>{index + 1})</b> {annotation.data.comentario}
                    </span>
                  )
                )}
            </div>
          </Box>
        </Modal>

        {question.corrections[0].annotations.length > 0 && (
          <ActionButton
            text="Ver anotações"
            Icon={EventNoteIcon}
            color="rgb(var(--accent-color1)"
            onClick={() => setOpenAnotationsModal(true)}
          />
        )}

        {question.corrections[0]?.messages.length === 0 &&
        question.corrections[0].status !== "Pending" ? (
          <ReviseQuestionItem
            {...{
              ActionButton,
              is_responding,
              setIsResponding,
              new_user_message,
              setNewUserMessage,
              handleSubmit,
            }}
          />
        ) : (
          <AnswerQuestionItem
            {...{
              ActionButton,
              question,
              is_responding,
              setIsResponding,
              new_user_message,
              setNewUserMessage,
              handleSubmit,
            }}
          />
        )}
      </div>
    </Box>
  );
};

export default QuestionProvaItem;
