import { Box, Modal } from "@mui/material";
import CustomText from "components/CustomText";
import form_styles from "components/SpecificStyles/form.module.css";
import MobileButton from "components/MobileButton";
import CloseIcon from "@mui/icons-material/Close";

const ComplaintModal = ({
  open_complaint_modal,
  setOpenComplaintModal,
  complaint_message,
}) => {
  return (
    <Modal
      open={open_complaint_modal}
      onClose={() => setOpenComplaintModal(false)}
    >
      <Box
        className={form_styles.customFormStyle}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: "50rem",
          maxHeight: "98vh",
          backgroundColor: "white",
          fontFamily: "Sans-serif",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            position: "relative",
            padding: {
              xs: "1rem",
              sm: "1rem",
              md: "2rem",
            },
            gap: "1rem",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "1rem",
              right: "1rem",
              cursor: "pointer",
            }}
          >
            <MobileButton
              icon={CloseIcon}
              action={() => setOpenComplaintModal(false)}
            />
          </div>
          <CustomText
            text="Denunciar mensagem"
            variant="h4"
            style={{ textAlign: "center", fontSize: "2rem" }}
          />

          <CustomText
            text="Sentimos muito que você tenha se sentido ofendido com alguma mensagem. Por favor, nos informe o motivo da denúncia."
            variant="p"
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              margin: "1rem auto",
              maxWidth: "40rem",
            }}
          />

          {/* TODO: message */}
          <div
            style={{
              padding: "0.8rem 1rem",
              backgroundColor: "var(--background-color2)",
              borderRadius: "0.4rem",
              flexDirection: "column",
              position: "relative",
              gap: "0.4rem",
            }}
          >
            <CustomText
              variant="span"
              text={`${complaint_message.user}:`}
              style={{
                fontSize: "1rem",
                textAlign: "justify",
                width: "100%",
                fontWeight: 600,
              }}
            />
            <CustomText
              variant="span"
              text={complaint_message.message}
              style={{
                fontSize: "1rem",
                textAlign: "justify",
                width: "100%",
              }}
            />
          </div>

          <textarea
            style={{
              backgroundColor: "var(--background-color2)",
              borderRadius: "0.5rem",
              padding: "1rem",
              border: "none",
              fontFamily: "var(--Poppins)",
              resize: "vertical",
              fontSize: "1rem",
            }}
            placeholder="Motivo da denúncia"
            // value={new_user_message}
            // onChange={(e) => setNewUserMessage(e.target.value)}
          />

          <input
            type="submit"
            value="Enviar"
            style={{
              maxWidth: "12rem",
              marginTop: "2rem",
            }}
            // onClick={handleSubmit}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default ComplaintModal;
