import ComponentList from "components/ComponentList";
import ListItemRow from "components/ListItemRow";
import { useEffect, useState } from "react";
import { post } from "utilities/requests";
import ProvaView from "./view";
import { Route, Routes, useLocation } from "react-router-dom";
import LoadingDots from "components/LoadingDots";
import { Box } from "@mui/material";

const ProvasPage = ({
  mobile_path,
  view_exam_mobile,
  setViewExamMobile = () => {},
}) => {
  const location = useLocation();
  const [id, setId] = useState("");
  const [student_application, setStudentApplication] = useState(null);
  const [exams, setExams] = useState([]);
  const [is_loading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const handleFetchExam = async (id) => {
    if (!id) return;

    let form = new FormData();
    form.append("exam_application_id", id);
    const res = await post(`student_application/get-exams`, form);

    if (res.status === "success") {
      return res.data;
    } else {
      throw new Error("Error fetching exams");
    }
  };

  useEffect(() => {
    try {
      setId(location.pathname.split("provas/")[1].split("/")[0]);
    } catch (err) {}
  }, [location]);

  useEffect(() => {
    setIsLoading(true);
    handleFetchExam(id).then((data) => {
      if (data) {
        setStudentApplication(data);
        setExams(data.exam_application.exams || []);
      }
    });
    setIsLoading(false);
  }, [id]);

  return (
    <Box
      className="Transition-hover"
      sx={{
        width: "100%",
        overflow: "hidden",
        height: "100%",
        position: "relative",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row",
        },
      }}
    >
      <Box
        className="Transition-opacity"
        sx={{
          width: {
            xs: "100vw",
            sm: "100vw",
            md: "40%",
          },
          minWidth: "20rem",
          position: "relative",
        }}
      >
        <ComponentList
          title="Provas"
          list={exams}
          student_application={student_application}
          search={{
            value: search,
            onChange: setSearch,
          }}
        >
          {exams.map((item, index) => (
            <ListItemRow
              key={index}
              id={item._id}
              img={"https://picsum.photos/50" + index}
              title={item.name}
              tags={
                [
                  // "Matemática",
                  // "Ciências",
                  // "Português",
                ]
              }
              createdAt={item.createdAt}
              description={item.description}
              path_route={`/admin/provas/${id}`}
              onSelect={() => setViewExamMobile(true)}
            />
          ))}
          {exams.length > 0 && (
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                },
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                color: "var(--text2)",
                padding: "2rem 0",
              }}
            >
              Fim da lista
            </Box>
          )}
          {exams.length === 0 && !is_loading && (
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                color: "var(--text2)",
                padding: "2rem 0",
              }}
            >
              Não há provas
            </span>
          )}
          {is_loading && <LoadingDots />}
        </ComponentList>
      </Box>

      <Box
        sx={{
          width: {
            xs: "100vw",
            sm: "100vw",
            md: "100%",
          },
          height: "100%",
          overflow: "hidden",
          // backgroundColor: "var(--background-color1)",
          border: "1px solid rgb(224, 224, 224)",
          padding: "0",
        }}
      >
        <Routes>
          <Route
            path={mobile_path || "/*"}
            element={<ProvaView {...{ student_application }} />}
          />
        </Routes>
      </Box>
    </Box>
  );
};

export default ProvasPage;
