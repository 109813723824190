import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";

import { useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CardHeader from "./CardHeader";
import EstudarPage from "../estudar";
import HighLight from "./Highlight";

const EstudoIndividualHome = () => {
  const [search, setSearch] = useState("");
  const [remove_preview_warning, setRemovePreviewWarning] = useState(false);

  return (
    <div
      className="Transition-opacity"
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: remove_preview_warning ? "auto" : "hidden",
        gap: "2rem",
        padding: "2rem 1rem",
        position: "relative",
      }}
    >
      <div
        style={{
          display: remove_preview_warning ? "none" : "flex",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          position: "absolute",
          zIndex: 10,
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "auto",
            padding: "1rem 2rem",
            backgroundColor: "white",
            borderRadius: "1rem",
            boxShadow: "0 0 1rem rgba(0, 0, 0, 0.2)",
            maxWidth: "50rem",
          }}
        >
          <h1>Preview do Estudo Individual</h1>
          <p
            style={{
              textAlign: "justify",
            }}
          >
            A função permite estudo autodidata, escolhendo provas prontas ou
            questões específicas de um amplo banco de dados. Destinada a
            aprimorar a experiência de estudo, fornece acesso a diversas
            questões de qualidade. No momento, está em modo de demonstração.
          </p>
          <button
            style={{
              display: "flex",
              gap: "0.5rem",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "1rem",
              backgroundColor: "rgba(var(--accent-color1), 0.6)",
              width: "fit-content",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              color: 'white'
            }}
            onClick={() => setRemovePreviewWarning(true)}
          >
            Espiar
            <VisibilityIcon
              style={{
                fontSize: "1.5rem",
              }}
            />
          </button>
        </div>
      </div>

      <CardHeader {...{ search, setSearch }} />

      <HighLight />
    </div>
  );
};

export default EstudoIndividualHome;
