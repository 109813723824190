import AsideItem from "./AsideItem";
import { useState, useEffect } from "react";
import { Box } from "@mui/system";
// import user_icon from "assets/user_icon.svg";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// icons
import LastPageRoundedIcon from "@mui/icons-material/LastPageRounded";
import { handleLogout } from "utilities/helpers";
import CustomText from "components/CustomText";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import swal from "sweetalert";
import SocialMedias from "./SocialMedias";

const Aside = ({ pages }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  const [shrunk, setShrunk] = useState(false);

  const handleShrunk = (e) => {
    setShrunk(!shrunk);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShrunk(true);
      } else {
        setShrunk(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "none",
          md: "flex",
        },
        flexDirection: "column",
        alignItems: "center",
        // width: shrunk ? "7.2rem" : "18rem",
        width: shrunk ? "5.3rem" : "18rem",
        backgroundColor: "#FFF",
        borderRight: "1px solid #E0E0E0",
        paddingTop: "1rem",
        transition: "0.3s ease-in-out",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: shrunk ? "center" : "space-between",
          alignItems: "center",
          width: "100%",
          padding: "0 0.6rem",
          marginBottom: "1rem",
        }}
      >
        {!shrunk && (
          <Box
            sx={{
              cursor: "pointer",
              padding: "0.3rem 0.5rem",
              borderRadius: "0.5rem",
              display: "flex",
              alignItems: "center",
              gap: "0.4rem",
              ":hover": {
                backgroundColor: "#E0E0E0",
              },
            }}
            onClick={() => {
              swal({
                title: "Sair?",
                text: "Você será deslogado do sistema!",
                icon: "warning",
                buttons: ["Cancelar", "Sair"],
                dangerMode: true,
              }).then((willLogout) => {
                if (willLogout) {
                  handleLogout(dispatch, navigate);
                }
              });
            }}
          >
            <PowerSettingsNewIcon />
            {shrunk ? null : <span>Sair</span>}
          </Box>
        )}
        <Box
          sx={{
            cursor: "pointer",
            padding: "0.3rem 0.5rem",
            borderRadius: "0.5rem",
            display: "flex",
            alignItems: "center",
            gap: "0.4rem",
            ":hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={handleShrunk}
        >
          <LastPageRoundedIcon
            style={{
              transform: shrunk ? "scale(1, 1)" : "scale(-1, 1)",
              transition: "0.3s ease-in-out",
            }}
          />
          {shrunk ? null : <span>Encolher</span>}
        </Box>
      </div>

      <div
        style={{
          padding: "0 1rem",
          width: "100%",
        }}
      >
        <Box
          className="Transition-hover"
          sx={{
            gap: shrunk ? "0" : "0.8rem",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#E0E0E0",
            },
          }}
          onClick={() => navigate("/admin/perfil")}
        >
          <img
            src={"https://picsum.photos/200"}
            alt="Usuário"
            style={{
              width: "3rem",
              borderRadius: "0.6rem",
              aspectRatio: "1/1",
              margin: "auto",
            }}
          />
          <div
            className="Transition-hover"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              maxWidth: shrunk ? "0" : "20rem",
              opacity: shrunk ? "0" : "1",
              overflow: "hidden",
            }}
          >
            <span
              style={{
                fontSize: "0.8rem",
                fontWeight: "bold",
                color: "var(--text-color1)",
              }}
            >
              Meu perfil
            </span>
            <CustomText
              variant="span"
              text={user?.name}
              lines={1}
              style={{
                fontSize: "0.9rem",
              }}
            />
          </div>
        </Box>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 0",
          width: "100%",
          gap: "0.4rem",
          height: "100%",
          overflowY: "auto",
        }}
      >
        {/* <AsideItem
          icon={DashboardRoundedIcon}
          title="Dashboard"
          href="dashboard"
          {...{ shrunk }}
        /> */}
        {pages.map(
          (page, index) =>
            !page.mobile_only && (
              <AsideItem
                key={index}
                icon={page.icon}
                title={page.title}
                href={page.href}
                {...{ shrunk }}
              />
            )
        )}
      </div>

      {/* social medias */}
      <SocialMedias />
    </Box>
  );
};

export default Aside;
