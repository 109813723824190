import { Container, Typography } from "@mui/material";
const { default: CustomSection } = require("components/CustomSection");

// import icon1 from "assets/static/random/icon1.jpg";
// import icon2 from "assets/static/random/icon2.jpg";
// import icon3 from "assets/static/random/icon3.jpg";

// const items = [
//   {
//     title: "Estrutura robusta",
//     text: "A estrutura tecnológica do OnTest proporciona grandes volumes de tráfego de dados sem prejudicar o desempenho para fiscais, aplicadores e alunos.",
//     icon: icon1,
//   },
//   {
//     title: "Suporte na tomada de decisão",
//     text: "Com a Inteligência Artificial do OnTest, a avaliação de candidatos e a tomada de decisão frente às situações delicadas se torna fácil.",
//     icon: icon2,
//   },
//   {
//     title: "Praticidade",
//     text: "Economize o seu tempo na gestão da criação, logística e aplicação das provas. Além disso, tenha relatórios detalhados de ações do candidato.",
//     icon: icon3,
//   },
// ];

const AboutUs = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-color1)"
      id="o-que-e"
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "10rem 1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          O que é o OnTest?
        </Typography>

        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: 400,
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: "var(--text-color1)",
            maxWidth: "60rem",
            margin: "0 auto",
          }}
        >
          Sua Chave para <b>Estudo Eficiente</b> e <b>Personalizado</b>.
          Controle sua jornada de aprendizado, criando provas personalizadas ou
          escolhendo desafios prontos. Cada questão vem com explicações
          detalhadas em texto, vídeo ou imagem, garantindo total compreensão.
        </Typography>

        {/* <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          {items.map((item, index) => (
            <AboutUsItem
              key={index}
              title={item.title}
              text={item.text}
              icon={item.icon}
            />
          ))}
        </Box> */}
      </Container>
    </CustomSection>
  );
};

export default AboutUs;
