const disciplines = [
  {
    id: 1,
    name: "Matemática",
    exams: [
      {
        id: 1,
        name: "Matemática aplicada",
        text_color: "#000", // White text color
        bg_color: "#FFD9C1", // Apricot background color
        img: "https://picsum.photos/401",
        institution: "UFRJ",
        rate: 4,
      },
      {
        id: 2,
        name: "Álgebra Linear",
        text_color: "#000000", // Black text color
        bg_color: "#D3FFCE", // Pale Green background color
        img: "https://picsum.photos/402",
        institution: "Universidade de São Paulo",
        rate: 4.5,
      },
      // More exams for the discipline of Matemática
      {
        id: 3,
        name: "Cálculo Integral",
        text_color: "#000", // White text color
        bg_color: "#FFEDF0", // Misty Rose background color
        img: "https://picsum.photos/403",
        institution: "Universidade Estadual do Rio de Janeiro",
        rate: 4.2,
      },
      {
        id: 4,
        name: "Geometria Descritiva",
        text_color: "#000", // White text color
        bg_color: "#FFEEE0", // Light Peach background color
        img: "https://picsum.photos/404",
        institution: "Universidade Federal de Minas Gerais",
        rate: 3.9,
      },
      {
        id: 5,
        name: "Estatística Descritiva",
        text_color: "#000000", // Black text color
        bg_color: "#E0FFFF", // Light Cyan background color
        img: "https://picsum.photos/405",
        institution: "Universidade de Brasília",
        rate: 4.8,
      },
      {
        id: 6,
        name: "Cálculo Diferencial",
        text_color: "#000", // White text color
        bg_color: "#FFE4B5", // Moccasin background color
        img: "https://picsum.photos/406",
        institution: "Universidade Federal do Rio de Janeiro",
        rate: 4.7,
      }
    ],
  },
  {
    id: 2,
    name: "Eletromecânica",
    exams: [
      {
        id: 1,
        name: "Fundamentos de Eletromecânica",
        text_color: "#000", // White text color
        bg_color: "#FFEDF0", // Misty Rose background color
        img: "https://picsum.photos/406",
        institution: "Instituto Federal de Tecnologia",
        rate: 4.2,
      },
      {
        id: 2,
        name: "Máquinas Elétricas",
        text_color: "#000000", // Black text color
        bg_color: "#D3FFCE", // Pale Green background color
        img: "https://picsum.photos/407",
        institution: "Universidade Estadual do Rio de Janeiro",
        rate: 4.7,
      },
      // More exams for the discipline of Eletromecânica
      {
        id: 3,
        name: "Eletrônica Analógica",
        text_color: "#000", // White text color
        bg_color: "#FFEEE0", // Light Peach background color
        img: "https://picsum.photos/408",
        institution: "Universidade de São Paulo",
        rate: 3.8,
      },
      {
        id: 4,
        name: "Sistemas de Controle",
        text_color: "#000000", // Black text color
        bg_color: "#E0FFFF", // Light Cyan background color
        img: "https://picsum.photos/409",
        institution: "Universidade Federal de Minas Gerais",
        rate: 4.5,
      },
    ],
  },
  {
    id: 3,
    name: "Programação",
    exams: [
      {
        id: 1,
        name: "Introdução à Programação",
        text_color: "#000", // White text color
        bg_color: "#FFD9C1", // Apricot background color
        img: "https://picsum.photos/410",
        institution: "Universidade Federal de Minas Gerais",
        rate: 4.6,
      },
      {
        id: 2,
        name: "Desenvolvimento Web Avançado",
        text_color: "#000000", // Black text color
        bg_color: "#D3FFCE", // Pale Green background color
        img: "https://picsum.photos/411",
        institution: "Universidade de Brasília",
        rate: 4.9,
      },
      // More exams for the discipline of Programação
      {
        id: 3,
        name: "Algoritmos e Estruturas de Dados",
        text_color: "#000", // White text color
        bg_color: "#FFEDF0", // Misty Rose background color
        img: "https://picsum.photos/412",
        institution: "Universidade Estadual de Campinas",
        rate: 4.2,
      },
      {
        id: 4,
        name: "Linguagens de Programação",
        text_color: "#000000", // Black text color
        bg_color: "#FFEEE0", // Light Peach background color
        img: "https://picsum.photos/413",
        institution: "Universidade Federal do Rio de Janeiro",
        rate: 4.7,
      },
      {
        id: 5,
        name: "Programação Orientada a Objetos",
        text_color: "#000", // White text color
        bg_color: "#FFE4B5", // Moccasin background color
        img: "https://picsum.photos/414",
        institution: "Universidade de São Paulo",
        rate: 4.8,
      },
    ],
  },
  {
    id: 4,
    name: "Ciências Sociais",
    exams: [
      {
        id: 1,
        name: "Sociologia Geral",
        text_color: "#000", // White text color
        bg_color: "#FFD9C1", // Apricot background color
        img: "https://picsum.photos/407",
        institution: "Universidade Federal do Rio de Janeiro",
        rate: 4.3,
      },
      {
        id: 2,
        name: "Antropologia Cultural",
        text_color: "#000000", // Black text color
        bg_color: "#D3FFCE", // Pale Green background color
        img: "https://picsum.photos/408",
        institution: "Universidade de São Paulo",
        rate: 4.8,
      },
      // More exams for the discipline of Ciências Sociais
      {
        id: 3,
        name: "Psicologia Social",
        text_color: "#000", // White text color
        bg_color: "#FFEDF0", // Misty Rose background color
        img: "https://picsum.photos/409",
        institution: "Universidade de Brasília",
        rate: 4.1,
      },
      {
        id: 4,
        name: "História Contemporânea",
        text_color: "#000000", // Black text color
        bg_color: "#FFEEE0", // Light Peach background color
        img: "https://picsum.photos/410",
        institution: "Universidade Estadual de Campinas",
        rate: 4.5,
      },
    ],
  },
  {
    id: 5,
    name: "Línguas e Literatura",
    exams: [
      {
        id: 1,
        name: "Literatura Brasileira Contemporânea",
        text_color: "#000000", // Black text color
        bg_color: "#FFD9C1", // Apricot background color
        img: "https://picsum.photos/409",
        institution: "Universidade Estadual de Campinas",
        rate: 4.4,
      },
      {
        id: 2,
        name: "Inglês Avançado",
        text_color: "#000", // White text color
        bg_color: "#D3FFCE", // Pale Green background color
        img: "https://picsum.photos/410",
        institution: "Universidade Federal de Minas Gerais",
        rate: 4.1,
      },
      // More exams for the discipline de Línguas e Literatura
      {
        id: 3,
        name: "Teoria Literária",
        text_color: "#000000", // Black text color
        bg_color: "#FFEDF0", // Misty Rose background color
        img: "https://picsum.photos/411",
        institution: "Universidade Estadual do Rio de Janeiro",
        rate: 4.9,
      },
      {
        id: 4,
        name: "Língua Espanhola",
        text_color: "#000", // White text color
        bg_color: "#FFEEE0", // Light Peach background color
        img: "https://picsum.photos/412",
        institution: "Universidade de São Paulo",
        rate: 4.7,
      },
    ],
  },
];

export default disciplines;
