import CustomText from "components/CustomText";
import AplicacoesTableSummary from "./Summary";
import { useState } from "react";
import AplicacoesTableSection from "./Table";
import { useQuery } from "react-query";
import { get } from "utilities/requests";
import AplicacoesItems from "./Items";
import { Box } from "@mui/material";

export default function AplicacoesTableView() {
  const [selected_filters, setSelectedFilters] = useState([]);

  const { data, refetch, isLoading } = useQuery(
    "ExamApplication_getAllMineAsStudent",
    async () => await get(`tests/all-student`)
  );
  const applications = data?.data || [];

  return (
    <Box
      className="Transition-opacity"
      sx={{
        flexDirection: "column",
        height: "100vh",
        overflow: "hidden",
        width: "100%",
        gap: "1rem",
        padding: "2rem 1rem",
        position: "relative",
        height: "100vh",
        overflowY: {
          xs: "auto",
          sm: "auto",
          md: "hidden",
        },
      }}
    >
      <CustomText
        text="Aplicações"
        variant={"p"}
        style={{
          fontSize: {
            xs: "1.6rem",
            sm: "1.8rem",
            md: "2.2rem",
          },
          fontWeight: 700,
          paddingLeft: "0.6rem",
        }}
      />

      <AplicacoesTableSummary
        {...{
          selected_filters,
          setSelectedFilters,
        }}
      />

      <br />

      <span
        style={{
          color: "var(--text-color2)",
          fontSize: "1.1rem",
          paddingLeft: "0.6rem",
        }}
      >
        Escolha a opção desejada para visualizar as aplicações.
      </span>

      <AplicacoesItems {...{ applications }} />
    </Box>
  );
}
