import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import logo from "assets/static/logo/logo.png";

export default function SocialMedias() {
  const social_medias_button_style = {
    fontSize: "2.1rem",
    opacity: "0.6",
    cursor: "pointer",
    color: "var(--accent-color1)",
    "&:hover": { opacity: 1 },
  };

  const social_medias = [
    {
      link: "https://www.instagram.com/enterscience_br/",
      icon: <InstagramIcon sx={social_medias_button_style} />,
    },
    {
      link: "https://www.facebook.com/enterscience",
      icon: <FacebookIcon sx={social_medias_button_style} />,
    },
    {
      link: "https://www.linkedin.com/company/enterscience/",
      icon: <LinkedInIcon sx={social_medias_button_style} />,
    },
    // {
    //   link: "https://twitter.com/enterscience_br",
    //   icon: <TwitterIcon sx={social_medias_button_style} />,
    // }
  ];

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "2rem",
        }}
      >
        {social_medias.map((social_media, index) => (
          <a key={index} href={social_media.link} target={"_blank"}>
            {social_media.icon}
          </a>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "end",
        }}
      >
        {/* logo */}
        {/* <a href="/">
          <img
            style={{
              width: "70%",
            }}
            src={logo}
            alt="Logotipo da Blueen Open"
          />
        </a> */}
        <span>
          by{" "}
          <a
            href="https://enterscience.com.br/"
            target="_blank"
            style={{
              width: "max-content",
              fontWeight: "bold",
            }}
          >
            EnterScience
          </a>
        </span>
      </div>
    </div>
  );
}
