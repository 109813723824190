import { Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavbarButton = ({
  icon = null,
  img = null,
  title = null,
  href = null,
  // index = null,
  // section_index,
  // setSectionIndex,
  setViewExamMobile,
  pages,
}) => {
  let navigate = useNavigate();
  const location = useLocation();

  const [current_section, setCurrentSection] = useState(false);

  useEffect(() => {
    // check if this route is active to set background color
    if (location.pathname.replace("/admin/", "").includes(href)) {
      setCurrentSection(true);
    } else {
      setCurrentSection(false);
    }
  }, [location]);

  return (
    <div
      className="Transition-hover"
      style={{
        width: "4rem",
        height: "4rem",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: current_section
          ? "rgb(var(--accent-color1))"
          : "transparent",
        bottom: current_section ? "1.4rem" : "0",
        position: "relative",
        borderRadius: "50%",
        // padding: "1rem",
      }}
      onClick={() => {
        navigate("/admin/" + href);
      }}
    >
      {icon && (
        <Icon
          className="Transition-hover"
          sx={{
            fontSize: current_section ? "2.4rem" : "1.8rem",
            color: current_section
              ? "var(--background-color1)"
              : "var(--text-color1)",
          }}
          component={icon}
        />
      )}

      {img && (
        <img
          className="Transition-hover"
          src={img}
          style={{
            width: current_section ? "2.4rem" : "1.8rem",
            height: current_section ? "2.4rem" : "1.8rem",
            borderRadius: "50%",
          }}
        />
      )}

      <span
        className="Transition-hover"
        style={{
          fontSize: current_section ? "0rem" : "0.7rem",
          fontWeight: "bold",
          textAlign: "center",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </span>

      <div
        // className="Transition-hover"
        style={{
          position: "absolute",
          bottom: current_section ? "-1.2rem" : "2rem",
          textAlign: "center",
          opacity: current_section ? 1 : 0,
          transition: "0.3s ease-in-out",
        }}
      >
        <span
          style={{
            fontSize: "0.7rem",
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

export default NavbarButton;
