/**
 * @param {String} text
 * @param {String} variant
 * @param {Object} style
 *
 **/

import { Box } from "@mui/material";

const CustomText = ({ text, className, variant, lines = null, style }) => {
  return (
    <Box
      className={className}
      component={variant}
      sx={[
        lines && {
          display: "-webkit-box",
          WebkitLineClamp: lines,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        {
          color: "var(--text-color1)",
        },
        style,
      ]}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default CustomText;
