import MoodIcon from "@mui/icons-material/Mood";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

export const maskDifficulty = (difficulty) => {
  let dif_icon = null;
  let dif_color = null;
  switch (difficulty) {
    case "Fácil":
      dif_icon = MoodIcon;
      dif_color = "#00b012";
      break;
    case "Médio":
      dif_icon = SentimentNeutralIcon;
      dif_color = "#e3b200";
      break;
    case "Difícil":
      dif_icon = SentimentVeryDissatisfiedIcon;
      dif_color = "#cf0000";
      break;
  }

  return { dif_icon, dif_color };
};

export const indexToLetter = (index) => {
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return alphabet[parseInt(index)];
};
