/**
 * @param {String} id
 * @param {String} path_route
 * @param {String} img
 * @param {String} title
 * @param {String} description
 * @param {String} createdAt
 * @param {Array} actions
 * @param {Object} style
 * @param {Function} onSelect
 *
 * @example
 *
 * <ListItemRow
 * img="https://picsum.photos/500"
 * title="Título"
 * description="Descrição"
 * path_route="/admin/provas/"
 **/

import { Box } from "@mui/material";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { maskDate } from "utilities/helpers";
import AnnouncementIcon from "@mui/icons-material/Announcement";

const ListItemRow = ({
  id,
  path_route,
  img,
  title,
  tags,
  description,
  createdAt,
  actions,
  style,
  onSelect,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [this_item, setThisItem] = useState(false);

  useEffect(() => {
    // check if this route is active to set background color
    if (location.pathname.includes(id)) {
      setThisItem(true);
    } else {
      setThisItem(false);
    }
  }, [location]);

  return (
    <Box
      className="Transition-hover"
      sx={{
        gap: "0.6rem",
        padding: this_item ? "0.6rem" : "0.6rem 0",
        cursor: "pointer",
        backgroundColor: this_item ? "rgb(var(--accent-color1), 0.7)" : "#fff",
        alignItems: "center",
        position: "relative",

        "&:hover": {
          backgroundColor: this_item
            ? "rgba(var(--accent-color1), 0.8)"
            : "rgba(var(--accent-color1), 0.1)",
          padding: "0.6rem",
        },

        ...style,
      }}
      onClick={() => {
        navigate(`${path_route}/visualizar/${id}`);
        onSelect();
      }}
    >
      {/* <AnnouncementIcon
        sx={{
          position: "absolute",
          top: "0.2rem",
          right: "0.2rem",
          color: this_item ? "var(--background-color1)" : "var(--accent-color1)",
          fontSize: "1.7rem",
          position: "absolute",
        }}
      /> */}

      <img
        src={img}
        alt={title}
        style={{
          width: "4.5rem",
          height: "4.5rem",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          // gap: "0.6rem",
        }}
      >
        <CustomText
          text={title}
          variant="p"
          lines={1}
          style={{
            fontSize: "1rem",
            fontWeight: 600,
            color: this_item
              ? "var(--background-color1)"
              : "var(--text-color1)",
          }}
        />
        <CustomText
          text={description}
          variant="p"
          lines={1}
          style={{
            fontSize: "0.8rem",
            color: this_item
              ? "var(--background-color2)"
              : "var(--text-color2)",
          }}
        />
        <CustomText
          text={maskDate(createdAt)}
          variant="p"
          lines={1}
          style={{
            fontSize: "0.7rem",
            color: this_item
              ? "var(--background-color2)"
              : "var(--text-color2)",
          }}
        />
        <CustomText
          text={tags.join(", ")}
          variant="p"
          lines={1}
          style={{
            fontSize: "0.7rem",
            color: this_item
              ? "var(--background-color2)"
              : "var(--text-color2)",
          }}
        />
      </div>
    </Box>
  );
};

export default ListItemRow;
