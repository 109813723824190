import CustomText from "components/CustomText";
import { maskDate } from "utilities/helpers";

const OwnerProvaItem = ({ owner, data }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <img
        src={"https://picsum.photos/300"}
        alt={owner?.name}
        style={{
          width: "4.5rem",
          height: "4.5rem",
          borderRadius: "50%",
        }}
      />

      <div
        style={{
          flexDirection: "column",
          justifyContent: "center",
          gap: "0.4rem",
        }}
      >
        <div
          style={{
            gap: "0.6rem",
          }}
        >
          <CustomText
            variant="p"
            style={{
              fontSize: "1rem",
              fontWeight: 600,
            }}
            text={owner?.name}
          />
          •
          <CustomText
            variant="p"
            style={{
              fontSize: "1rem",
            }}
            text={owner?.role}
          />
        </div>
        <div
          style={{
            gap: "0.6rem",
          }}
        >
          <CustomText
            variant="p"
            style={{
              fontSize: "1rem",
            }}
            text={maskDate(data.created_at)}
          />
        </div>
      </div>
    </div>
  );
};

export default OwnerProvaItem;
