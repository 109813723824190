import Landing from "./Landing";
// import Clientes from "./Clientes";
// import FAQPage from "../FAQ";
import Contato from "../Contato";
import AboutUs from "./AboutUs";
import AltaTendencia from "./AltaTendencia";
import SubPlans from "./SubPlans";
import MVPSection from "./MVPSection";

const HomePage = ({ on_top }) => {
  return (
    <>
      <Landing {...{ on_top }} />
      <AboutUs />
      <MVPSection />
      <AltaTendencia />
      <SubPlans />
      <Contato invert_bg_theme={true} />
    </>
  );
};

export default HomePage;
