const PlaceholderDiv = ({ image, children }) => {
  return (
    <div
      className="Transition-opacity"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        margin: "auto",
        alignItems: "center",
        height: "100%",
        width: "80%",
        gap: "0.6rem",
        maxWidth: "30rem",
      }}
    >
      {children}

      <img
        src={image}
        alt="EnterScience Logotipo"
        style={{
          width: "20rem",
          objectFit: "contain",
          marginTop: "2rem",
        }}
      />
    </div>
  );
};

export default PlaceholderDiv;
