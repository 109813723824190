import { Box, Icon } from "@mui/material";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import LaptopMacOutlinedIcon from "@mui/icons-material/LaptopMacOutlined";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import ToggleOffOutlinedIcon from "@mui/icons-material/ToggleOffOutlined";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";

export default function AplicacoesTableSummary({
  selected_filters,
  setSelectedFilters,
}) {
  //
  return (
    <div
      style={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <div
        style={{
          gap: "2rem",
          alignItems: "center",
        }}
      >
        <CustomText
          text="Aplique o filtro desejado"
          variant={"p"}
          style={{
            color: "var(--text-color2)",
            fontSize: "1.1rem",
            paddingLeft: "0.6rem",
          }}
        />
        <button
          style={{
            display: selected_filters.length === 0 ? "none" : "flex",
            background: "rgba(var(--accent-color1), 0.7)",
            color: "var(--background-color0)",
            fontSize: "1rem",
            fontWeight: 700,
            padding: "0.3rem 1.2rem",
            borderRadius: "1rem",
          }}
          onClick={() => setSelectedFilters([])}
        >
          Limpar filtros
        </button>
      </div>
      <div
        style={{
          flexWrap: "wrap",
          width: "100%",
          gap: "1rem",
        }}
      >
        <SummaryItem
          icon={ApartmentOutlinedIcon}
          title="Presenciais"
          value="10"
          filter_name="presencial"
          {...{ selected_filters, setSelectedFilters }}
        />

        <SummaryItem
          icon={LaptopMacOutlinedIcon}
          title="On-line"
          value="10"
          filter_name="online"
          {...{ selected_filters, setSelectedFilters }}
        />
        <SummaryItem
          icon={AccessAlarmOutlinedIcon}
          title="Em andamento"
          value="10"
          filter_name="em-andamento"
          {...{ selected_filters, setSelectedFilters }}
        />
        <SummaryItem
          icon={EventBusyOutlinedIcon}
          title="Encerradas"
          value="10"
          filter_name="encerrada"
          {...{ selected_filters, setSelectedFilters }}
        />
      </div>
    </div>
  );
}

function SummaryItem({
  icon,
  title,
  value,
  filter_name,
  selected_filters,
  setSelectedFilters,
}) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selected_filters.includes(filter_name)) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selected_filters]);

  return (
    <Box
      sx={{
        background: checked
          ? "rgba(var(--accent-color1), 0.7)"
          : "linear-gradient(45deg, var(--background-color2) 0%, var(--background-color0) 100%)",
        gap: "1rem",
        border: "1px solid var(--background-color0)",
        backgroundSize: "200% 100%",
        backgroundPosition: checked ? "left" : "right",
        padding: "1rem 1.2rem",
        cursor: "pointer",
        color: checked ? "var(--background-color0)" : "var(--text-color2)",
        borderRadius: "1rem",
        alignItems: "center",
        transition: "0.3s ease",
        position: "relative",
        "&:hover": {
          backgroundPosition: "left",
          border: "1px solid var(--background-color2)",
        },
      }}
      onClick={() => {
        if (selected_filters.includes(filter_name)) {
          setSelectedFilters(
            selected_filters.filter((item) => item !== filter_name)
          );
        } else {
          setSelectedFilters([...selected_filters, filter_name]);
        }
      }}
    >
      <div
        style={{
          position: "absolute",
          right: "0.4rem",
          bottom: "0",
        }}
      >
        {checked ? <ToggleOnIcon /> : <ToggleOffOutlinedIcon />}
      </div>
      <Icon
        component={icon}
        sx={{
          borderRadius: "1rem",
          display: "flex",
          width: "2.6rem",
          height: "2.6rem",
          justifyContent: "center",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingRight: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1rem",
          }}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: "1.2rem",
            fontWeight: 700,
          }}
        >
          {value}
        </span>
      </div>
    </Box>
  );
}
