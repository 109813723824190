import { useEffect, useState } from "react";
import { get, post } from "utilities/requests";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingDots from "components/LoadingDots";
import CustomText from "components/CustomText";
import { Box, Container, Modal } from "@mui/material";
import OwnerProvaItem from "./Owner";
import QuestionProvaItem from "./Question";
import empty_icon from "assets/static/empty.svg";

const ProvaView = ({ student_application }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [owner, setOwner] = useState({});
  const [questions, setQuestions] = useState([]);

  // get answered questions from exam by user
  const handleGetAllAnswers = async (exam) => {
    if (student_application === null) {
      return navigate("/admin/aplicacoes");
    }

    let form = new FormData();
    form.append("exam_id", exam._id);
    form.append("student_application_id", student_application._id);

    const res = await post(
      `student_application/get-all-answers-from-exam`,
      form
    );

    let new_questions = [];
    res.data.forEach((answered_question, index) => {
      const question = answered_question.question;
      question.number_index = index + 1;

      question.alternatives.forEach((alternative) => {
        // add checked inside correct alternative
        if (
          answered_question.objective_answer !== undefined &&
          alternative._id === answered_question.objective_answer._id
        ) {
          alternative.checked = true;
        } else {
          alternative.checked = false;
        }
      });

      // add essay_answer inside question
      question.essay_answer = answered_question.dissertative_answer || "";

      // insert corrections inside question
      question.corrections = answered_question.corrections;

      // check if question was not answered
      if (answered_question.objective_answer === undefined) {
        question.not_answered = true;
      }

      // check if its part of a set and insert it
      if (question.question_father !== undefined) {
        let father = new_questions.find(
          (q) => q._id === question.question_father._id
        );
        if (father) {
          father.questions.push(question);
        } else {
          father = question.question_father;
          father.questions = [question];
          new_questions.push(father);
        }
      } else {
        new_questions.push(question);
      }
    });
    setQuestions(new_questions);
  };

  const handleGetExam = async (id) => {
    get(`tests/get-exam-by-id/${id}`).then((res) => {
      setData(res.data);
      setOwner(res.data.owner);
      handleGetAllAnswers(res.data);
    });
  };

  useEffect(() => {
    let exam_id = null;
    try {
      exam_id = location.pathname.split("visualizar/")[1].split("/")[0];
    } catch (error) {}

    if (exam_id)
      handleGetExam(location.pathname.split("visualizar/")[1].split("/")[0]);
  }, [location]);

  return !data ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={empty_icon}
        alt="Ícone de aviso"
        style={{
          maxWidth: "20rem",
          width: "100%",
          objectFit: "contain",

          margin: "0 auto",
        }}
      />

      <CustomText
        variant="h1"
        text="Selecione uma prova para visualizar"
        style={{
          marginTop: "1rem",
          textAlign: "center",
        }}
      />
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        overflowY: "auto",
        paddingTop: "2rem",
      }}
    >
      <div
        style={{
          animation: "slideFromRight 1s",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <CustomText
            variant="p"
            lines={1}
            style={{
              fontSize: "2.4rem",
              fontWeight: "bold",
            }}
            text={data.name}
          />
          <CustomText
            variant="p"
            style={{
              fontSize: "1.1rem",
              textAlign: "justify",
            }}
            text={data.description}
          />

          <div>
            {/* {data.tags.map((tag, index) => (
              <CustomText
                key={index}
                variant="span"
                text={tag}
                style={{
                  fontSize: "1rem",
                  backgroundColor: "var(--background-color2)",
                  padding: "0.2rem 0.4rem",
                  borderRadius: "0.4rem",
                  marginRight: "0.4rem",
                }}
              />
            ))} */}
          </div>

          <OwnerProvaItem {...{ owner, data }} />

          {questions.map((question, index) => {
            if (question.type === "set") {
              return (
                <Box
                  sx={{
                    flexDirection: "column",
                    gap: "1rem",
                    backgroundColor: "rgba(var(--accent-color1), 0.03)",
                    border: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  <CustomText
                    variant="span"
                    text={question.question_content}
                    style={{
                      fontSize: "1.1rem",
                      textAlign: "justify",
                      width: "100%",
                      padding: {
                        xs: "0.6rem 0.8rem",
                        sm: "1rem 1.4rem",
                        md: "1.6rem 2rem",
                      },
                      paddingBottom: "0!important",
                    }}
                  />
                  {question.questions.map((q, i) => (
                    <QuestionProvaItem
                      key={i}
                      {...{ question: q, index: i, ActionButton }}
                    />
                  ))}
                </Box>
              );
            } else {
              return (
                <QuestionProvaItem
                  key={index}
                  {...{ question, index, ActionButton }}
                />
              );
            }
          })}

          {/* {questions.length === 0 && (
            <EmptyWarning
              message="Nenhuma questão encontrada"
              parentStyle={{
                marginTop: "4rem",
              }}
            />
          )} */}

          <div
            style={{
              height: "2rem",
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default ProvaView;

const ActionButton = ({ text, Icon, color, onClick }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        backgroundColor: `${color}, 0.8)`,
        color: "var(--background-color1)",
        overflow: "hidden",
        width: "fit-content",
        // maxWidth: {
        //   xs: "20rem",
        //   sm: "3rem",
        // },
        gap: "0.5rem",
        alignItems: "center",
        padding: "0.5rem",
        transition: "all 800ms 2s ease",

        // "&:hover": {
        //   maxWidth: "20rem",
        //   transition: "all 1s ease",
        // },
      }}
      onClick={onClick}
    >
      <Icon
        sx={{
          width: "2rem",
          height: "2rem",
        }}
      />
      <span
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
    </Box>
  );
};
