import CustomSection from "components/CustomSection";
import MVPItem from "./MVPItem";
import { Box, Typography } from "@mui/material";

import img1 from 'assets/static/home/slide_0.jpg';
import img2 from 'assets/static/home/slide_1.jpg';
import img3 from 'assets/static/home/slide_2.jpg';
import img4 from 'assets/static/home/slide_3.jpg';

const items = [
  {
    title: "Estudo Individual Customizado",
    text: "Modele sua experiência de aprendizado de acordo com suas necessidades.",
    icon: img1,
  },
  {
    title: "Plano de Estudo Personalizado",
    text: "Desenvolva planos específicos para atingir seus objetivos acadêmicos.",
    icon: img2,
  },
  {
    title: "Vestibulares e Concursos",
    text: "Navegue por uma vasta gama de questões para fortalecer seu conhecimento.",
    icon: img3,
  },
  {
    title: "Filtros e Provas Personalizadas",
    text: "Ajuste os parâmetros para criar avaliações alinhadas com seus objetivos.",
    icon: img4,
  },
];

export default function MVPSection() {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        alignItems: "center",
      }}
      style_child
      bg_color="var(--background-color0)"
      id="mvp-section"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: "3rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          Estudo Eficiente ao Seu Alcance
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
            marginTop: "2rem",
            justifyContent: "center",
          }}
        >
          {items.map((item, index) => (
            <MVPItem
              key={index}
              title={item.title}
              text={item.text}
              icon={item.icon}
            />
          ))}
        </Box>
      </div>
    </CustomSection>
  );
}
