import form_styles from "components/SpecificStyles/form.module.css";
import swal from "sweetalert";
import { get } from "utilities/requests";

export default function AplicacoesResponderFormControllers({
  student_application,
  selected_exam,
  setSelectedExam,
  question_index,
  setQuestionIndex,
  all_questions,
}) {
  const handleNextQuestion = () => {
    if (question_index < all_questions.length - 1) {
      setQuestionIndex((prev) => prev + 1);
    } else {
      const exams_length = student_application.exam_application.exams.length;
      const exam_index =
        student_application.exam_application.exams.indexOf(selected_exam);

      if (exam_index < exams_length - 1) {
        setSelectedExam(
          student_application.exam_application.exams[exam_index + 1]
        );
        setQuestionIndex(0);
      } else {
        swal({
          title: "Finalizar teste? Todas as questões foram respondidas!",
          text: "Uma vez finalizado, você não poderá mais alterar suas respostas!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willFinish) => {
          if (willFinish) {
            const res = await get(
              `student_application/student-finish-test/${student_application._id}`
            );
            if (res.status === "success") {
              swal("Finalizado com sucesso!", {
                icon: "success",
              }).then(() => {
                window.location.href = "/admin/aplicacoes";
              });
            }
          }
        });
      }
    }
  };

  const handlePreviousQuestion = () => {
    if (question_index > 0) {
      setQuestionIndex((prev) => prev - 1);
    } else {
      const exam_index =
        student_application.exam_application.exams.indexOf(selected_exam);

      if (exam_index > 0) {
        setSelectedExam(
          student_application.exam_application.exams[exam_index - 1]
        );
        setQuestionIndex(
          student_application.exam_application.exams[exam_index - 1].questions
            .length - 1
        );
      } else {
        alert("Você já está na primeira questão!");
      }
    }
  };

  return (
    <div
      className={form_styles.customFormStyle}
      style={{
        justifyContent: "space-between",
        marginTop: "1rem",
        width: "100%",
        gap: "1rem",
      }}
    >
      <div>
        <input
          type="submit"
          value="Questão anterior"
          style={{
            maxWidth: "max-content",
            background:
              "linear-gradient(310deg, rgba(52,109,143,1) 0%, rgba(113,116,178,1) 100%)",
          }}
          onClick={handlePreviousQuestion}
        />
      </div>
      <input
        type="submit"
        value={"Próxima questão"}
        style={{
          maxWidth: "max-content",
          background: "linear-gradient(310deg, #348F50 0%, #71B280 100%)",
          color: "var(--text-color1)!important",
        }}
        onClick={handleNextQuestion}
      />
    </div>
  );
}
