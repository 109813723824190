import { Box } from "@mui/material";

const MVPItem = ({ title, text, icon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        gap: "2rem",
        flexDirection: "column",
        maxWidth: "24rem",

        background: "linear-gradient(180deg, var(--background-color0) 0%, var(--background-color1) 100%)",
        borderRadius: "1rem",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box
        component="img"
        src={icon}
        alt="MVP Icon"
        sx={{
          width: "100%",
          height: "14rem",
          objectFit: "cover",
          borderRadius: "0",
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
          textAlign: "center",
          padding: "0 1rem 1rem 1rem",
        }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: "1.3rem",
          }}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: "1rem",
            textAlign: "center",
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </Box>
    </Box>
  );
};

export default MVPItem;
