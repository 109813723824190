import { useEffect, useState } from "react";

export default function ResponderFormIndexSelector({
  question,
  index,
  question_index,
  setQuestionIndex,
}) {
  const [already_answered, setAlreadyAnswered] = useState(false);

  useEffect(() => {
    question.alternatives.forEach((alternative) => {
      if (alternative.checked) {
        setAlreadyAnswered(true);
      }
    });
  }, [question]);

  return (
    <div
      key={index}
      style={{
        height: "2rem",
        width: "2rem",
        borderRadius: "0.6rem",
        border: "1px solid rgba(0,0,0,0.2)",
        backgroundColor:
          question_index === index
            ? "rgb(var(--accent-color0), 1)"
            : already_answered
            ? "rgba(var(--accent-color1), 0.7)"
            : "var(--background-color1)",
        justifyContent: "center",
        alignItems: "center",
        color:
          question_index === index
            ? "var(--background-color1)"
            : already_answered
            ? "var(--background-color1)"
            : "var(--text-color2)",
        fontWeight: 700,
        fontSize: "1.2rem",
        cursor: "pointer",
      }}
      onClick={() => setQuestionIndex(index)}
    >
      {index + 1}
    </div>
  );
}
