import CustomText from "components/CustomText";
import FlagIcon from "@mui/icons-material/Flag";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { useEffect, useState } from "react";
import ComplaintModal from "../Complaint";

export default function AnswerQuestionItem({
  ActionButton,
  question,
  is_responding,
  setIsResponding,
  new_user_message,
  setNewUserMessage,
  handleSubmit,
}) {
  const user = JSON.parse(localStorage.getItem("user"));
  const [can_answer, setCanAnswer] = useState(false);
  const [pending, setPending] = useState(false);
  const [open_complaint_modal, setOpenComplaintModal] = useState(false);
  const [complaint_message, setComplaintMessage] = useState("");

  const checkIfIsCorrectorMessage = (message) => {
    const corrector_id = question.corrections[0].corrector;
    return user._id === corrector_id;
  };

  // TODO: check if the corrector has already answered
  useEffect(() => {
    let messages = [];
    let corrector_id = "";

    if (question.corrections !== undefined) {
      messages = question.corrections[0].messages;
      corrector_id = question.corrections[0].corrector;

      // check if correction is pending
      if (question.corrections[0].status === "Pending") setPending(true);
      else setPending(false);
    }

    if (messages[messages.length - 1] === undefined) return;

    if (corrector_id === messages[messages.length - 1].user._id) {
      setCanAnswer(true);
    }
  }, [question]);

  return (
    <>
      <ComplaintModal
        {...{
          open_complaint_modal,
          setOpenComplaintModal,
          complaint_message,
          setComplaintMessage,
        }}
      />
      <h3
        style={{
          paddingLeft: "0.2rem",
          color: "var(--text-color2)",
          marginTop: "1rem",
        }}
      >
        Revisão
      </h3>

      {question.corrections !== undefined &&
        question.corrections[0].messages.map((message, index) => (
          <div
            key={index}
            style={{
              flexDirection: "column",
            }}
          >
            <div
              key={index}
              style={{
                padding: "0.4rem 0.8rem",
                backgroundColor: checkIfIsCorrectorMessage(message)
                  ? "var(--background-color2)"
                  : "rgba(var(--accent-color0), 0.1)",
                borderRadius: "0.4rem",
                flexDirection: "column",
                position: "relative",
              }}
            >
              {/* <NewReleasesIcon
                sx={{
                  position: "absolute",
                  top: "0.2rem",
                  right: "0.2rem",
                  color: "var(--accent-color1)",
                  fontSize: "1.4rem",
                  position: "absolute",
                }}
              /> */}
              <CustomText
                variant="span"
                text={`${message.user.name}:`}
                style={{
                  fontSize: "1rem",
                  textAlign: "justify",
                  width: "100%",
                  fontWeight: 600,
                }}
              />
              <CustomText
                variant="span"
                text={message.message}
                style={{
                  fontSize: "1rem",
                  textAlign: "justify",
                  width: "100%",
                }}
              />
            </div>
            {checkIfIsCorrectorMessage(message) && (
              <button
                style={{
                  fontSize: "0.9rem",
                  color: "white",
                  backgroundColor: "rgb(var(--accent-color0)",
                  width: "fit-content",
                  cursor: "pointer",
                  display: "flex",
                  alignSelf: "flex-end",
                  padding: "0.4rem 0.4rem",
                  borderRadius: "0.4rem",
                  marginTop: "0.4rem",
                  marginBottom: "1rem",
                  gap: "0.2rem",
                }}
                onClick={() => {
                  setOpenComplaintModal(true);
                  setComplaintMessage(message);
                }}
              >
                <ReportProblemIcon sx={{ fontSize: "1rem" }} />
                Denunciar mensagem
              </button>
            )}
          </div>
        ))}

      {is_responding && (
        <div
          style={{
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <textarea
            style={{
              backgroundColor: "var(--background-color2)",
              borderRadius: "0.5rem",
              padding: "1rem",
              border: "none",
              fontFamily: "var(--Poppins)",
              resize: "vertical",
              fontSize: "1rem",
            }}
            placeholder="Explique o motivo da revisão. Min. 10 caracteres."
            value={new_user_message}
            onChange={(e) => setNewUserMessage(e.target.value)}
          />
          <div
            style={{
              gap: "1rem",
            }}
          >
            {new_user_message.length > 9 && (
              <ActionButton
                text="Enviar"
                Icon={FlagIcon}
                color="rgb(var(--accent-color0)"
                onClick={handleSubmit}
              />
            )}
            <ActionButton
              text="Cancelar"
              Icon={HighlightOffIcon}
              color="rgb(var(--accent-color1)"
              onClick={() => setIsResponding(false)}
            />
          </div>
        </div>
      )}

      {!can_answer && !pending ? (
        <span
          style={{
            fontSize: "1rem",
            paddingLeft: "0.2rem",
            color: "var(--text-color2)",
          }}
        >
          Aguardando resposta do professor...
        </span>
      ) : (
        pending && (
          <span
            style={{
              fontSize: "1rem",
              paddingLeft: "0.2rem",
              color: "var(--text-color2)",
              alignItems: "center",
              display: "flex",
              gap: "0.4rem",
            }}
          >
            <InfoOutlinedIcon /> Revisão indisponível porque a correção está
            pendente.
          </span>
        )
      )}

      {!is_responding && can_answer && (
        <ActionButton
          text="Responder"
          Icon={FlagIcon}
          color="rgb(var(--accent-color1)"
          onClick={() => setIsResponding(true)}
        />
      )}
    </>
  );
}
