import CustomText from "components/CustomText";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import disciplines from "./data";
import { useNavigate } from "react-router-dom";
import DisciplineCard from "./DisciplineCard";

const HighLight = () => {
  const navigate = useNavigate();
  return (
    <>
      <CustomText
        text="Disciplinas em destaque"
        variant={"p"}
        style={{
          fontSize: {
            xs: "1.6rem",
            sm: "1.8rem",
            md: "2.2rem",
          },
          fontWeight: 700,
          textAlign: "center",
          width: "100%",
          padding: "2rem 0",
        }}
      />

      {disciplines.map((discipline, index) => (
        <div
          key={index}
          style={{
            flexDirection: "column",
            gap: "0.6rem",
            // height: "40rem",
            width: "100%",
            marginBottom: "2rem",
          }}
        >
          <div
            style={{
              background: "var(--background-color1)",
            }}
          >
            <CustomText
              key={index}
              text={discipline.name}
              style={{
                fontSize: "1.8rem",
                textAlign: "justify",
                fontWeight: 600,
                padding: "0 1rem",
              }}
            />
          </div>
          <Swiper
            slidesPerView={2}
            spaceBetween={10}
            navigation={true}
            grabCursor={true}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1300: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1600: {
                slidesPerView: 5,
                spaceBetween: 20,
              },
            }}
            modules={[Navigation]}
          >
            {discipline.exams.map((exam, index) => (
              <SwiperSlide
                key={index}
                style={{
                  padding: "1rem 0",
                }}
              >
                <DisciplineCard
                  exam={exam}
                  onClick={() =>
                    navigate(`/admin/estudo-individual/visualizar`)
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ))}
    </>
  );
};

export default HighLight;
