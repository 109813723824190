import { Box } from "@mui/system";

import './style.css';

const dot_style = {
  animation: 'loading 1.4s cubic-bezier(.55,-0.77,.42,1.79) 0s infinite normal both running',
  background: 'rgb(var(--accent-color1))',
  borderRadius: '50%',
  display: 'block',
  height: '1rem',
  width: '1rem',
}

const LoadingDots = () => {
  return (
    <div
      className="Transition-opacity"
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "4rem 20px",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "72px",
          margin: "0 auto",
          width: "100%",
        }}
      >
        <span style={{
          ...dot_style,
          animationDelay: '-0.72s',
          animationName: 'animation_loading_dots',
        }}></span>
        <span style={{
          ...dot_style,
          animationDelay: '-0.48s',
          animationName: 'animation_loading_dots',
        }}></span>
        <span style={{
          ...dot_style,
          animationDelay: '-0.24s',
          animationName: 'animation_loading_dots',
        }}></span>
      </div>
    </div>
  );
};

export default LoadingDots;
