import { Route, Routes } from "react-router-dom";
import EstudoIndividualHome from "./home";
import EstudoIndividualView from "./view";
import EstudarPage from "./estudar";
import ProvaFinalizadaPage from "./estudar/ProvaFinalizada";

const EstudoIndividualPage = ({ mobile_path = "" }) => {
  return (
    <div
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        gap: "2rem",
      }}
    >
      <Routes>
        <Route
          path={mobile_path + "visualizar/*"}
          element={<EstudoIndividualView />}
        />
        <Route path={mobile_path + "estudar/*"} element={<EstudarPage />} />
        <Route
          path={mobile_path + "estudar/finalizado"}
          element={<ProvaFinalizadaPage />}
        />
        <Route path={mobile_path + "*"} element={<EstudoIndividualHome />} />
      </Routes>
      {/* <EstudoIndividualHome /> */}
    </div>
  );
};

export default EstudoIndividualPage;
