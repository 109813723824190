import { Box, Modal } from "@mui/material";
import CustomInput from "components/CustomInput";
import CustomText from "components/CustomText";
import form_styles from "components/SpecificStyles/form.module.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "utilities/helpers";
import { get } from "utilities/requests";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import password_img from "assets/static/Perfil/password.svg";
import accessibility_img from "assets/static/Perfil/accessibility.png";
import theme_img from "assets/static/Perfil/theme.svg";
import EditarPerfilPage from "./EditarPerfil";
import swal from "sweetalert";

const PerfilPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userinfo, setUserinfo] = useState({});
  const [open_profile_edit_modal, setOpenProfileEditModal] = useState(false);
  const [open_accessibility_modal, setOpenAccessibilityModal] = useState(false);
  const [open_theme_modal, setOpenThemeModal] = useState(false);

  const handleGetUserInfo = async () => {
    const res = await get(`me`);
    // const { data } = await get(`questions/tags?search=${search}&page=1`);
    return res;
  };

  useEffect(() => {
    handleGetUserInfo().then((data) => {
      setUserinfo(data.data);
    });
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        flexDirection: "column",
      }}
      className={form_styles.customFormStyle}
    >
      <EditarPerfilPage
        {...{ userinfo, open_profile_edit_modal, setOpenProfileEditModal }}
      />

      <Modal
        open={open_accessibility_modal || open_theme_modal}
        onClose={() => {
          setOpenAccessibilityModal(false);
          setOpenThemeModal(false);
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            width: "100%",
            maxWidth: "30rem",
            maxHeight: "30rem",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            fontFamily: "Sans-serif",
            gap: "1rem",
          }}
        >
          <CustomText
            text={"Página em construção"}
            variant={"h1"}
            style={{
              fontSize: {
                xs: "1.6rem",
                sm: "1.8rem",
                md: "2rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
          <CustomText
            text={"Aguarde novidades em breve!"}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
        </Box>
      </Modal>

      <div
        style={{
          gap: "2rem",
          position: "relative",
          justifyContent: "space-between",
          height: "20rem",
        }}
      >
        <img
          src={"https://picsum.photos/1200/300"}
          alt={userinfo.name}
          style={{
            width: "100%",
            height: "60%",
            objectFit: "cover",
            borderRadius: "0",
            position: "absolute",
            zIndex: 1,
          }}
        />

        <Box
          sx={{
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
            },
            gap: "1rem",
            padding: {
              xs: "1rem",
              sm: "1.6rem",
              md: "2rem",
            },
            zIndex: 10,
            alignItems: "end",
            justifyContent: "end",
            position: "relative",
            top: {
              xs: "3rem",
              sm: "0",
              md: "0",
            },
          }}
        >
          <Box
            component={"img"}
            src={"https://picsum.photos/300"}
            alt={userinfo.name}
            sx={{
              width: {
                xs: "8rem",
                sm: "12rem",
                md: "20rem",
              },
              aspectRatio: "1/1",
              maxWidth: "12rem",
              maxHeight: "12rem",
              borderRadius: "50%",
              border: "0.4rem solid var(--background-color0)",
            }}
          />
          <CustomText
            variant="h2"
            text={userinfo.name}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1.6rem",
                md: "2rem",
              },
              fontWeight: 600,
              textAlign: "center",
              marginBottom: "1.6rem",
            }}
          />
        </Box>

        <div
          style={{
            display: "flex",
            gap: "1rem",
            padding: "2rem",
            zIndex: 10,
            alignItems: "start",
            justifyContent: "end",
          }}
        >
          <Box
            component={"input"}
            type="submit"
            value="Editar perfil"
            sx={{
              maxWidth: "max-content",
              background:
                "linear-gradient(310deg, rgba(52,109,143,1) 0%, rgba(113,116,178,1) 100%)",
              // marginBottom: "1.6rem",
              fontSize: {
                xs: "0.7rem",
                sm: "1rem",
                md: "1.2rem",
              },
            }}
            onClick={() => {
              setOpenProfileEditModal(true);
            }}
          />
          <Box
            sx={{
              position: "absolute",
              right: "2rem",
              top: "2rem",
              cursor: "pointer",
              padding: "0.3rem 0.5rem",
              borderRadius: "0.5rem",
              display: "flex",
              alignItems: "center",
              gap: "0.4rem",
              backgroundColor: "#E0E0E0",
            }}
            onClick={() => {
              swal({
                title: "Sair?",
                text: "Você será deslogado do sistema!",
                icon: "warning",
                buttons: ["Cancelar", "Sair"],
                dangerMode: true,
              }).then((willLogout) => {
                if (willLogout) {
                  handleLogout(dispatch, navigate);
                }
              });
            }}
          >
            <PowerSettingsNewIcon />
            <span>Sair dessa conta</span>
          </Box>
        </div>
      </div>

      <Box
        sx={{
          flexWrap: "wrap",
          gap: "2rem",
          padding: "2rem",
          overflowY: "auto",
          marginTop: {
            xs: "2rem",
            sm: "0",
            md: "0",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "var(--background-color1)",
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "2rem 1rem",
              sm: "2rem 1.4rem",
              md: "2rem 1.6rem",
            },
            gap: "1rem",
            borderRadius: "2rem",
            flexDirection: "column",
            flexGrow: 1,
            width: "20rem",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "var(--background-color2)",
            },
          }}
          onClick={() => {
            setOpenProfileEditModal(true);
          }}
        >
          <CustomText
            text={"Senha e E-mail"}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1.6rem",
                sm: "1.8rem",
                md: "2rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
          <img
            src={password_img}
            alt="Ícone de aviso"
            style={{
              width: "10rem",
              height: "10rem",
            }}
          />
          <CustomText
            text={"Deseja alterar sua senha ou e-mail?"}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "var(--background-color1)",
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "2rem 1rem",
              sm: "2rem 1.4rem",
              md: "2rem 1.6rem",
            },
            gap: "1rem",
            borderRadius: "2rem",
            flexDirection: "column",
            flexGrow: 1,
            width: "20rem",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "var(--background-color2)",
            },
          }}
          onClick={() => {
            setOpenAccessibilityModal(true);
          }}
        >
          <CustomText
            text={"Acessibilidade"}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1.6rem",
                sm: "1.8rem",
                md: "2rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
          <img
            src={accessibility_img}
            alt="Ícone de aviso"
            style={{
              width: "10rem",
              height: "10rem",
            }}
          />
          <CustomText
            text={"Altere o tamanho da fonte, contraste e mais!"}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "var(--background-color1)",
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "2rem 1rem",
              sm: "2rem 1.4rem",
              md: "2rem 1.6rem",
            },
            gap: "1rem",
            borderRadius: "2rem",
            flexDirection: "column",
            flexGrow: 1,
            width: "20rem",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "var(--background-color2)",
            },
          }}
          onClick={() => {
            setOpenThemeModal(true);
          }}
        >
          <CustomText
            text={"Tema"}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1.6rem",
                sm: "1.8rem",
                md: "2rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
          <img
            src={theme_img}
            alt="Ícone de aviso"
            style={{
              width: "10rem",
              height: "10rem",
            }}
          />
          <CustomText
            text={"Escolha entre o tema claro e escuro!"}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
        </Box>
      </Box>

      {/* <CustomInput
            type="email"
            placeholder="E-mail"
            icon={EmailIcon}
            value={email}
            onChange={setEmail}
            required
          />

          <CustomInput
            type={showPassword ? "text" : "password"}
            placeholder="Senha"
            icon={showPassword ? VisibilityOffIcon : VisibilityIcon}
            value={password}
            onChange={setPassword}
            required
            onClick={() => setShowPassword(!showPassword)}
          /> */}
      {/* <div>
          <input
            type="submit"
            value="Sair"
            style={{
              maxWidth: "12rem",
            }}
            onClick={() => {
              handleLogout(dispatch);
            }}
          />
        </div> */}
    </div>
  );
};

export default PerfilPage;
