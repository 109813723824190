import styles from "./style.module.css";
import empty_icon from "assets/static/empty.svg";
import AplicacoesTableItem from "./TableItem";
import CustomText from "components/CustomText";

export default function AplicacoesTableSection({
  applications,
  server_time,
  setServerTime,
}) {
  return (
    <div
      style={{
        width: "100%",
        overflowY: "auto",
        borderRadius: " 1rem 1rem 0 0",
        border: "solid 0.1rem var(--background-color2)",
      }}
    >
      <table className={styles.customTable}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Descrição</th>
            <th>Informações</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {
            // applications.map((exam) => (
            applications.map((application, index) => (
              <AplicacoesTableItem
                {...{
                  application,
                  server_time,
                  setServerTime,
                }}
                key={index}
              />
            ))
          }
          {applications.length === 0 && (
            <tr>
              <td colSpan="4">
                <div
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={empty_icon}
                    alt="Ícone de aviso"
                    style={{
                      maxWidth: "16rem",
                      width: "100%",
                      objectFit: "contain",

                      margin: "0 auto",
                    }}
                  />

                  <CustomText
                    variant="h3"
                    text="Não há aplicações disponíveis"
                    style={{
                      marginTop: "1rem",
                      textAlign: "center",
                    }}
                  />
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
