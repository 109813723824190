import { Box, Icon } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useEffect, useState } from "react";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import swal from "sweetalert";
import { get } from "utilities/requests";
import {
  diffDateInSeconds,
  formatCorrectDate,
} from "utilities/application/formatCorrectDate";

export default function AplicacoesResponderControllers({
  server_time,
  student_application,
}) {
  const [timer, setTimer] = useState(0);

  const handleFinishTest = async () => {
    swal({
      title: "Você tem certeza?",
      text: "Uma vez finalizado, você não poderá mais alterar suas respostas!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willFinish) => {
      if (willFinish) {
        const res = await get(
          `student_application/student-finish-test/${student_application._id}`
        );
        if (res.status === "success") {
          swal("Finalizado com sucesso!", {
            icon: "success",
          }).then(() => {
            window.location.href = "/admin/aplicacoes";
          });
        }
      }
    });
  };

  useEffect(() => {
    setTimer(
      diffDateInSeconds(
        server_time,
        formatCorrectDate(student_application.exam_application).end_date
      )
    );
  }, [server_time]);

  return (
    <div
      style={{
        position: "fixed",
        top: "1rem",
        right: "1.6rem",
        gap: "1rem",
        display: "flex",
      }}
    >
      <ControllerItem
        icon={CheckCircleOutlineIcon}
        text="Finalizar teste"
        bg_color="rgba(var(--accent-color1), 0.8)"
        text_color="var(--background-color1)"
        onClick={handleFinishTest}
      />

      <ControllerItem
        icon={AccessAlarmIcon}
        text={`Termina em <b>${timer}</b>`}
        bg_color="rgba(var(--accent-color0), 0.9)"
        text_color="var(--background-color1)"
      />
    </div>
  );
}

function ControllerItem({
  icon,
  text,
  bg_color,
  text_color,
  onClick = () => {},
}) {
  return (
    <Box
      sx={{
        cursor: "pointer",
        backgroundColor: bg_color,
        color: text_color,
        gap: "0.5rem",
        alignItems: "center",
        padding: "0.5rem 1rem",
      }}
      onClick={onClick}
    >
      <Icon
        component={icon}
        sx={{
          width: "2rem",
          height: "2rem",
        }}
      />
      <span
        style={{
          whiteSpace: "nowrap",
        }}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Box>
  );
}
