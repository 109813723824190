import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import DesktopRoutes from "./DesktopRoutes";
import MobileRoutes from "./MobileRoutes";

// icons
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SchoolIcon from "@mui/icons-material/School";

const pages = [
  {
    title: "Notificações",
    href: "notificacoes",
    icon: NotificationsIcon,
  },
  {
    title: "Aplicações",
    href: "aplicacoes",
    icon: SchoolIcon,
  },
  {
    title: "Estudo Individual",
    href: "estudo-individual",
    icon: AutoStoriesOutlinedIcon,
  },
  {
    title: "Perfil",
    href: "perfil",
    img: "https://picsum.photos/200",
    mobile_only: true,
  },
];

const AuthRoutes = () => {
  let navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [readyToRender, setReadyToRender] = useState(false);

  const checkLogin = () => {
    setReadyToRender(true);

    if (token === null) {
      return navigate("/login");
    } else {
      setReadyToRender(true);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    readyToRender && (
      <div
        style={{
          flexDirection: "column",
          height: "100vh",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <DesktopRoutes {...{ pages }} />

        {/* <MobileRoutes
          {...{
            pages,
            section_index,
            setSectionIndex,
            handleTouchStart,
            handleTouchEnd,
            view_exam_mobile,
            setViewExamMobile,
          }}
        /> */}
      </div>
    )
  );
};

export default AuthRoutes;
