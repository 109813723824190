import { useDispatch } from "react-redux";

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const image = file;
    if (!(file instanceof File)) return;
    if (image && FileReader) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
  });

export const toggleAppTheme = () => {
  const app = document.querySelector("body");

  if (app.className.includes("dark-mode")) {
    app.classList.remove("dark-mode");
    app.classList.add("white-mode");
    return "white-mode";
  } else {
    app.classList.add("dark-mode");
    app.classList.remove("white-mode");
    return "dark-mode";
  }
};

export const maskDate = (date) => {
  const data = new Date(date);
  const dia = String(data.getDate()).padStart(2, "0");
  const mes = String(data.getMonth() + 1).padStart(2, "0");
  const ano = data.getFullYear();
  const hora = data.getHours().toString().padStart(2, "0");
  const minuto = data.getMinutes().toString().padStart(2, "0");

  return `${dia}/${mes}/${ano} às ${hora}:${minuto}`;
};

export const handleLogout = (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("first_access");
  dispatch({ type: "login", payload: { token: null, user: null } });
  dispatch({ type: "cart_items", payload: null });
  window.location.href = "/";
};

export const getAlphabetLetterIndex = (str) => {
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  return parseInt(
    str.toLowerCase().replace(/[a-z]/g, (letter) => alphabet.indexOf(letter))
  );
};

export const formatDate = (date, show_time) => {
  const data = new Date(date);
  const dia = String(data.getDate()).padStart(2, "0");
  const mes = String(data.getMonth() + 1).padStart(2, "0");
  const ano = data.getFullYear();
  const hora = data.getHours().toString().padStart(2, "0");
  const minuto = data.getMinutes().toString().padStart(2, "0");

  if (show_time) {
    return `${dia}/${mes}/${ano} às ${hora}:${minuto}`;
  } else {
    return `${dia}/${mes}/${ano}`;
  }
};

export const formatTime = (seconds) => {
  if (seconds < 0) {
    return "O valor não pode ser negativo.";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.round(seconds % 60); // Arredondar para o número inteiro mais próximo

  const formatUnit = (value, unit) =>
    `${value} ${value === 1 ? unit : unit + "s"}`;

  let formattedTime;

  if (hours) {
    formattedTime = [
      formatUnit(hours, "hora"),
      minutes && formatUnit(minutes, "minuto"),
    ]
      .filter(Boolean)
      .join(" e ");
  } else if (minutes) {
    formattedTime = formatUnit(minutes, "minuto");
  } else {
    formattedTime = formatUnit(secs, "segundo");
  }

  return formattedTime || "0 segundos";
};
