// export const URL = "http://localhost:3010/";
// export const THIS_URL = "http://localhost:3000/";

export const URL = "https://lite-dev-api.ontest.com.br/";
export const THIS_URL = "https://www.lite-student.ontest.com.br/";

// export const URL = "https://lite-homolog-api.ontest.com.br/";
// export const THIS_URL = "https://www.lite-student.ontest.com.br/";

// http://lite-student.ontest.com.br/

export const API_URL = URL + "api/";
export const STORAGE_URL = URL;
