import { Icon, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const right_icon_theme = {
  color: "var(--text-color0)",
  fontSize: "1.8rem",
  position: "absolute",
  top: "50%",
  left: "0.8rem",
  transform: "translateY(-50%)",
};

const left_icon_theme = {
  color: "var(--text-color0)",
  fontSize: "1.8rem",
  position: "absolute",
  top: "50%",
  right: "0.8rem",
  transform: "translateY(-50%)",
};

/**
 *
 * @param {string} type
 * @param {string} placeholder
 * @param {string} value
 * @param {function} onChangeTarget
 * @param {Icon} icon
 * @param {boolean} reverse
 * @param {boolean} required
 * @param {boolean} disabled
 * @param {string} helperText
 * @param {function} onChange
 * @param {function} onClick
 * @param {object} inputProps
 * @param {object} inputStyle
 * @param {object} parentStyle
 *
 */

const CustomInput = ({
  type = "text",
  placeholder = "",
  value,
  onChangeTarget = null,
  icon = null,
  reverse = null,
  required = false,
  disabled = false,
  helperText = null,
  onChange = () => {},
  onClick = () => {},
  inputProps = {},
  inputStyle = {},
  parentStyle = {},
  params = {},
}) => {
  const [helper_text_hover, set_helper_text_hover] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: reverse ? "row-reverse" : "row",
        gap: "0.5rem",
        position: "relative",
        ...parentStyle,
      }}
      onClick={onClick}
    >
      {icon && <Icon component={icon} sx={right_icon_theme} />}

      {type === "password" && (
        <Icon
          sx={{
            ...left_icon_theme,
            color: "var(--text-color2)",
            cursor: "pointer",
            opacity: showPassword ? 0.6 : 1,
            "&:hover": {
              opacity: 1,
            },
          }}
          onClick={() => setShowPassword(!showPassword)}
          component={showPassword ? VisibilityOffIcon : VisibilityIcon}
        />
      )}

      <input
        type={type === "password" ? (showPassword ? "password" : "text") : type}
        placeholder={placeholder}
        value={value}
        onChange={
          onChangeTarget
            ? (e) => onChangeTarget(e)
            : (e) => onChange(e.target.value)
        }
        style={{
          paddingLeft: icon && !reverse ? "3.2rem" : "1rem",
          paddingRight: icon && reverse ? "3.2rem" : "1rem",
          ...inputStyle,
        }}
        disabled={disabled}
        {...inputProps}
        {...params}
      />

      {type !== "password" && (helperText || required) && (
        <InfoOutlinedIcon
          sx={{
            ...left_icon_theme,
            fontSize: "1.4rem",
          }}
          onMouseEnter={() => set_helper_text_hover(true)}
          onMouseLeave={() => set_helper_text_hover(false)}
        />
      )}

      {type !== "password" && (helperText || required) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "0.5rem 1rem",
            borderRadius: "0.4rem",
            backgroundColor: "var(--background-color1)",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
            position: "absolute",
            top: "1rem",
            right: "1.8rem",
            maxWidth: "20rem",
            fontSize: "0.9rem",
            gap: "0.5rem",
            opacity: helper_text_hover ? 1 : 0,
            visibility: helper_text_hover ? "visible" : "hidden",
            transition: "all 0.2s ease-in-out",
            zIndex: 2,
          }}
          onMouseEnter={() => set_helper_text_hover(true)}
          onMouseLeave={() => set_helper_text_hover(false)}
        >
          {helperText && (
            <div style={{ color: "var(--text-color0)" }}>{helperText}</div>
          )}

          {required && (
            <div style={{ color: "var(--text-color2)", fontWeight: 600 }}>
              Campo obrigatório.
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CustomInput;
