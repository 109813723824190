import { Route, Routes, useLocation } from "react-router-dom";
import GuestRoutes from "./GuestRoutes";
import AuthRoutes from "./AuthRoutes";
import { useEffect } from "react";
import { toast } from "react-toastify";

const RoutesContainer = () => {

  // check if user has first_access = true in localStorage
  // if true, redirect to /primeiro-acesso
  const location = useLocation();
  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("first_access") === "true") {
        if (location.pathname !== "/primeiro-acesso") {
          window.location.href = "/primeiro-acesso";
        }
      }
    }
  }, [location]);

  return (
    <Routes>
      <Route path={"/admin/*"} element={<AuthRoutes />} />
      <Route path={"/*"} element={<GuestRoutes />} />
    </Routes>
  )
}

export default RoutesContainer;