import { Container } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { get, post } from "utilities/requests";
import AplicacoesResponderExamsSection from "./ExamsContainer";
import { useEffect, useState } from "react";
import AplicacoesResponderForm from "./ResponderForm";
import AplicacoesResponderControllers from "./TopControllers";
import { formatCorrectDate } from "utilities/application/formatCorrectDate";
import swal from "sweetalert";
import LoadingDots from "components/LoadingDots";

export default function AplicacoesResponderPage() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [student_application, setStudentApplication] = useState(null);
  const [application, setApplication] = useState(null);
  const [exams, setExams] = useState([]);
  const [server_time, setServerTime] = useState(null);
  const [selected_exam, setSelectedExam] = useState(null);
  const [all_questions, setAllQuestions] = useState([]);

  const getStudentApplication = async () => {
    // const res = await get(`tests/all-exams-from-application/${id}`);
    let form = new FormData();
    form.append("exam_application_id", id);
    const res = await post(`student_application`, form);
    if (res.status === "success") {
      setStudentApplication(res.data);
      setApplication(res.data.exam_application);
      setExams(res.data.exam_application.exams);
      setSelectedExam(res.data.exam_application.exams[0]);
      setIsLoading(false);
      return res.data.exam_application;
    } else {
      swal({
        title: "Você já encerrou esta aplicação!",
        text: "Você será redirecionado para a página de aplicação.",
        icon: "info",
        button: "Ok",
      }).then(() => {
        window.location.href = "/admin/aplicacoes";
      });
    }
  };

  const checkIfApplicationIsAvailable = async (application) => {
    if (!application) return;

    get("student_application/get-current-time").then((res) => {
      if (res.status === "success") {
        const now = new Date(res.time);
        setServerTime(new Date(res.time));
        const { init_date, end_date } = formatCorrectDate(application);

        if (now < init_date || now > end_date) {
          swal({
            title: "Esta aplicação foi encerrada!",
            text: "Você será redirecionado para a página de aplicação.",
            icon: "info",
            button: "Ok",
          }).then(() => {
            window.location.href = "/admin/aplicacoes";
          });
        }
      }
    });
  };

  // get answered questions from exam by user
  const handleGetAllAnswers = async (exam) => {
    let form = new FormData();
    form.append("exam_id", exam._id);
    form.append("student_application_id", student_application._id);

    const res = await post(
      `student_application/get-all-answers-from-exam`,
      form
    );

    let new_questions = [];
    res.data.forEach((answered_question) => {
      let question = answered_question.question;

      // a set is a group of questions inside a question
      if (answered_question.question.type === "set") {
        let questions = question.questions;

        questions.forEach((question) => {
          question.alternatives.forEach((alternative) => {
            // add checked inside correct alternative
            if (
              answered_question.objective_answer !== undefined &&
              alternative._id === answered_question.objective_answer._id
            ) {
              alternative.checked = true;
            } else {
              alternative.checked = false;
            }
          });
        });

        return new_questions.push(question);
      }

      // normal question
      question.alternatives.forEach((alternative) => {
        // add checked inside correct alternative
        if (
          answered_question.objective_answer !== undefined &&
          alternative._id === answered_question.objective_answer._id
        ) {
          alternative.checked = true;
        } else {
          alternative.checked = false;
        }
      });

      // add essay_answer inside question
      question.essay_answer = answered_question.dissertative_answer || "";
      new_questions.push(question);
    });
    setAllQuestions(new_questions);
  };

  useEffect(() => {
    if (selected_exam !== null) handleGetAllAnswers(selected_exam);
  }, [selected_exam]);

  useEffect(() => {
    getStudentApplication().then((app) => {
      checkIfApplicationIsAvailable(app);
      setInterval(() => {
        checkIfApplicationIsAvailable(app);
      }, 10000);
    });
  }, []);

  return selected_exam === null || isLoading ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingDots />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        padding: "4rem 1rem",
        height: "100vh",
        width: "100%",
      }}
    >
      <Container
        maxWidth="lg"
        className="Transition-opacity"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <AplicacoesResponderControllers
            {...{
              server_time,
              student_application,
            }}
          />

          <AplicacoesResponderExamsSection
            {...{
              exams,
              selected_exam,
              setSelectedExam,
            }}
          />

          <br />

          {all_questions.length > 0 && (
            <AplicacoesResponderForm
              {...{
                student_application,
                application_id: application._id,
                selected_exam,
                setSelectedExam,
                all_questions,
                setAllQuestions,
              }}
            />
          )}
        </div>
      </Container>
    </div>
  );
}
