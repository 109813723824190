import form_styles from "components/SpecificStyles/form.module.css";
import search_img from "assets/static/EstudoIndividual/search.svg";
import leaf_right from "assets/static/EstudoIndividual/leaf_right.svg";
import leaf_left from "assets/static/EstudoIndividual/leaf_left.svg";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomInput from "components/CustomInput";
import MobileButton from "components/MobileButton";
import { Autocomplete, Box, Checkbox } from "@mui/material";
import CustomText from "components/CustomText";
import { get } from "utilities/requests";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CardHeader = ({ search, setSearch }) => {
  const navigate = useNavigate();
  const [disciplines, setDisciplines] = useState([
    { name: "Matemática" },
    { name: "Português" },
    { name: "Direito Constitucional" },
  ]);
  const [tags, setTags] = useState([]);

  const handleFetchExam = async () => {
    const { data } = await get(`disciplines?&search=${search}&page=1`);
    // const { data } = await get(`questions/tags?search=${search}&page=1`);
    return data;
  };

  const handleStartStudying = () => {
    console.log(tags);
    if (tags.length === 0) {
      return toast.warning("Selecione pelo menos uma disciplina!");
    }

    navigate("/admin/estudo-individual/estudar", {
      state: {
        tags,
      },
    });
  };

  // useEffect(() => {
  //   handleFetchExam().then((data) => {
  //     setDisciplines(data);
  //   });
  // }, []);

  return (
    <Box
      sx={{
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row-reverse",
        },
        background:
          "linear-gradient(180deg, var(--background-color1) 0%, var(--background-color0) 100%)",
        justifyContent: "center",
        alignItems: "center",
        padding: {
          xs: "3rem 0",
          sm: "3rem 0",
          md: "2rem 0",
        },
        position: "relative",
        gap: "2rem",
        borderRadius: "2rem",
      }}
    >
      <img
        src={leaf_right}
        alt="leaf"
        style={{
          width: "10vw",
          minWidth: "7rem",
          maxWidth: "11rem",
          position: "absolute",
          bottom: "0rem",
          right: "0rem",
          borderRadius: "2rem",
        }}
      />
      <img
        src={leaf_left}
        alt="leaf"
        style={{
          width: "10vw",
          minWidth: "7rem",
          maxWidth: "11rem",
          position: "absolute",
          bottom: "0rem",
          left: "0rem",
          borderRadius: "2rem",
        }}
      />

      <Box
        className={form_styles.customFormStyle}
        sx={{
          flexDirection: "column",
          gap: "0.2rem",
          // height: "40rem",
          marginBottom: "2rem",
          alignItems: {
            xs: "center",
            sm: "center",
            md: "flex-start",
          },
          padding: "1rem",
        }}
      >
        <CustomText
          text="Procurando por algo em específico?"
          style={{
            fontSize: {
              xs: "1.6rem",
              sm: "1.8rem",
              md: "2.2rem",
            },
            fontWeight: 600,
            textAlign: {
              xs: "center",
              sm: "center",
              md: "left",
            },
            marginBottom: {
              xs: "1rem",
              sm: "1rem",
              md: "0",
            },
          }}
        />
        <CustomText
          text="Faça uma busca por concurso, área ou disciplina!"
          style={{
            fontSize: {
              xs: "1rem",
              sm: "1.1rem",
              md: "1.2rem",
            },
            fontWeight: 600,
            marginBottom: "1rem",
            textAlign: {
              xs: "center",
              sm: "center",
              md: "left",
            },
          }}
        />
        <div
          style={{
            gap: "1rem",
          }}
        >
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={disciplines}
            getOptionLabel={(disciplines) => disciplines.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <CustomInput
                  placeholder="Buscar"
                  icon={SearchIcon}
                  value={search}
                  onChange={setSearch}
                  parentStyle={{
                    width: "100%",
                    display: "inline-block",
                  }}
                  helperText="Você pode buscar por concurso, área ou disciplina. Basta digitar o que deseja."
                  inputStyle={{
                    backgroundColor: "#e3f3fc",
                  }}
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              </div>
            )}
            onChange={(event, value) => {
              setTags(value);
            }}
          />
          {/* <CustomInput
            placeholder="Buscar"
            icon={SearchIcon}
            value={search}
            onChange={setSearch}
            helperText="Você pode buscar por concurso, área ou disciplina. Basta digitar o que deseja."
            inputStyle={{
              backgroundColor: "#e3f3fc",
            }}
          /> */}
          <MobileButton
            icon={SearchIcon}
            parentStyle={{
              backgroundColor: "rgba(148, 135, 249, 0.8)",
              padding: "0 0.9rem",
              borderRadius: "0.5rem",
              cursor: "pointer",
            }}
            iconStyle={{
              color: "#fff",
            }}
            action={() => handleStartStudying()}
          />
        </div>
      </Box>
      {/* <img src={search_img} alt="search" style={{ width: "20rem" }} /> */}
      <Box
        component="img"
        src={search_img}
        alt="search"
        sx={{
          width: "20rem",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
          },
        }}
      />
    </Box>
  );
};

export default CardHeader;
