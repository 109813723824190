import { Navigate, Route, Routes } from "react-router-dom";
import AsideNavbar from "components/Admin/AsideNavbar";
import ProvasPage from "pages/auth/Provas";
import EstudoIndividualPage from "pages/auth/EstudoIndividual";
import { Box, Typography } from "@mui/material";
import PlaceholderDiv from "components/PlaceholderDiv";
import CustomText from "components/CustomText";
import logo from "assets/static/logo/logo.png";
import PerfilPage from "pages/auth/Perfil";
import AplicacoesPage from "pages/auth/Aplicacoes";
import BottomNavbar from "components/Admin/BottomNavbar";

const DesktopRoutes = ({ pages }) => {
  return (
    <Box
      sx={{
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "row",
        },
        height: "100%",
        width: "100%",
        overflowY: "auto",
      }}
    >
      <AsideNavbar {...{ pages }} />

      <Routes>
        <Route path="/provas/*" element={<ProvasPage />} />

        <Route path="/aplicacoes/*" element={<AplicacoesPage />} />

        <Route path="/estudo-individual/*" element={<EstudoIndividualPage />} />

        <Route path="/perfil/*" element={<PerfilPage />} />

        {/* dashboard */}
        <Route
          path="/dashboard"
          element={
            <PlaceholderDiv image={logo}>
              <CustomText variant="h2" text="Seja bem-vindo(a) ao Dashboard." />
            </PlaceholderDiv>
          }
        />

        <Route
          path="/notificacoes"
          element={
            <PlaceholderDiv image={logo}>
              <CustomText variant="h1" text="Página de notificações." />
              <CustomText variant="h3" text="Em desenvolvimento." />
            </PlaceholderDiv>
          }
        />

        {/* generic 404 */}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>

      <BottomNavbar {...{ pages }} />
    </Box>
  );
};

export default DesktopRoutes;
