import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import { Typography } from "@mui/material";
import SwiperCarousel from "./SwiperContainer";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useState } from "react";
import ReactTyping from "./Typing";

const Landing = ({ on_top }) => {
  const [slide_index, setSlideIndex] = useState(0);

  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
      bg_color="var(--background-color0)"
      id="home"
      remove_border={true}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",

          justifyContent: "end",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "rgba(var(--accent-color1))",
            fontWeight: 800,
            fontSize: {
              xs: "3.5vh",
              sm: "4.5vh",
              md: "5vh",
              lg: "6vh",
            },
            lineHeight: "1.2",
            marginBottom: "2vh",
            textAlign: "center",
            zIndex: 2,
            maxWidth: "70rem",
          }}
        >
          Desperte Seu Potencial de{" "}
          <u style={{ color: "rgba(var(--accent-color0))" }}>
            Aprendizado Individual
          </u>
        </Typography>

        <div
          style={{
            maxWidth: "60rem",
            zIndex: 2,
            position: "absolute",
            bottom: "10vh",
            backgroundColor: "rgba(255, 255, 255, 0.4)",
            backdropFilter: "blur(10px)",
            borderRadius: "1rem",
            padding: "1rem 2rem",
            border: "1px solid #ccc",
            boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 50px",
          }}
        >
          <ReactTyping {...{
            setSlideIndex,
          }}
          />

          <KeyboardDoubleArrowDownIcon
            className="Transition-1"
            sx={{
              opacity: on_top ? 1 : 0,
              fontSize: "4.5rem",
              position: "absolute",
              bottom: on_top ? "-5.5rem" : "-4rem",
              left: "50%",
              transform: "translateX(-50%)",
              color: "rgba(var(--accent-color0))",
              cursor: "pointer",
            }}
            onClick={() => {
              const element = document.querySelector("#o-que-e");
              element.scrollIntoView({ behavior: "smooth" });
            }}
          />
        </div>

        <SwiperCarousel
          {...{
            slide_index,
          }}
        />
      </Container>
    </CustomSection>
  );
};

export default Landing;
