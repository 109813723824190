import {
  Autocomplete,
  Box,
  Checkbox,
  Container,
  LinearProgress,
} from "@mui/material";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import form_styles from "components/SpecificStyles/form.module.css";
import QuestionProvaItem from "./Question";
import { useLocation, useNavigate } from "react-router-dom";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import HighLight from "../home/Highlight";
import empty_icon from "assets/static/empty.svg";
import SearchIcon from "@mui/icons-material/Search";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomInput from "components/CustomInput";
import { handleGetTags } from "./data";
import swal from "sweetalert";

const EstudarPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [disciplines, setDisciplines] = useState([]);

  const [tags, setTags] = useState([
    "Matemática",
    "Português",
    "Geografia",
  ]);
  const [filter_tags, setFilterTags] = useState([]);
  const [start_study, setStartStudy] = useState(false);
  const [progress, setProgress] = useState(10);
  const [question_index, setQuestionIndex] = useState(0);

  const handleNextQuestion = () => {
    if (question_index === 9) {
      // confirmacao de finalizacao
      swal({
        title: "Você tem certeza?",
        text: "Você não poderá mais voltar para esta prova.",
        icon: "warning",
        buttons: ["Cancelar", "Finalizar"],
        dangerMode: true,
      }).then(() => {
        navigate("/admin/estudo-individual/estudar/finalizado");
      });
      return 
    }
    setQuestionIndex((prev) => prev + 1);
    setProgress((prev) => prev + 10);
  };

  const handlePreviousQuestion = () => {
    if (question_index === 0) return;
    setQuestionIndex((prev) => prev - 1);
    setProgress((prev) => prev - 10);
  };

  const handleSetQuestionIndex = (index) => {
    setQuestionIndex(index);
    setProgress((index + 1) * 10);
  };

  useEffect(() => {
    if (state) {
      setDisciplines(state.tags);
    }
  }, [state]);

  // useEffect(() => {
  //   handleGetTags().then((res) => {
  //     setTags(res.data);
  //   });
  // }, []);

  return !start_study ? (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        gap: "2rem",
        flexDirection: "column",
        padding: "2rem 1rem",
      }}
      className={form_styles.customFormStyle}
    >
      <Container
        className="Transition-opacity"
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          gap: "2rem",
          padding: "2rem 1rem",
        }}
      >
        <img
          src={empty_icon}
          alt="Ícone de aviso"
          style={{
            width: "14rem",
            height: "14rem",
            margin: "0 auto",
          }}
        />
        <CustomText
          text="Um total de 0 questões foram encontradas."
          variant={"p"}
          style={{
            fontSize: {
              xs: "1.6rem",
              sm: "1.8rem",
              md: "2.2rem",
            },
            fontWeight: 700,
            width: "100%",
            padding: "1rem 0",
          }}
        />

        <div
          style={{
            gap: "1rem",
            display: "flex",
            flexWrap: "wrap",
            borderRadius: "0.5rem",
            background: "var(--background-color1)",
            alignItems: "center",
            padding: "0.5rem 1rem",
          }}
        >
          <CustomText
            text="Disciplinas selecionadas:"
            variant={"p"}
            style={{
              fontWeight: 700,
            }}
          />
          {disciplines
            .reduce((acc, tag, index) => {
              return acc.concat(tag.name);
            }, [])
            .join(", ")}
        </div>

        <CustomText
          text="Outros filtros:"
          variant={"p"}
          style={{
            fontWeight: 700,
            fontSize: "1.2rem",
          }}
        />

        <div
          style={{
            gap: "1rem",
          }}
        >
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={tags}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <CustomInput
                  placeholder="Tags"
                  icon={SearchIcon}
                  parentStyle={{
                    width: "100%",
                    display: "inline-block",
                  }}
                  helperText="Selecione as tags desejadas para filtrar as questões"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              </div>
            )}
            onChange={(event, value) => {
              setFilterTags(value);
            }}
          />
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={tags}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <CustomInput
                  placeholder="Escolaridade"
                  icon={SearchIcon}
                  parentStyle={{
                    width: "100%",
                    display: "inline-block",
                  }}
                  helperText="Selecione as tags desejadas para filtrar as questões"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              </div>
            )}
            onChange={(event, value) => {
              setFilterTags(value);
            }}
          />
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={tags}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon />}
                  checkedIcon={<CheckBoxIcon />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <CustomInput
                  placeholder="Dificuldade"
                  icon={SearchIcon}
                  parentStyle={{
                    width: "100%",
                    display: "inline-block",
                  }}
                  helperText="Selecione as tags desejadas para filtrar as questões"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              </div>
            )}
            onChange={(event, value) => {
              setFilterTags(value);
            }}
          />
        </div>

        <div
          className={form_styles.customFormStyle}
          style={{
            justifyContent: "space-between",
          }}
        >
          <input
            type="submit"
            value="Alterar disciplinas"
            style={{
              maxWidth: "max-content",
            }}
            onClick={() => navigate("/admin/estudo-individual/")}
          />
          <input
            type="submit"
            value="Iniciar estudo"
            style={{
              maxWidth: "max-content",
              background: "linear-gradient(310deg, #348F50 0%, #71B280 100%)",
              color: "var(--text-color1)!important",
            }}
            onClick={() => setStartStudy(true)}
          />
        </div>
      </Container>
      <HighLight />
    </div>
  ) : (
    <Container
      maxWidth="md"
      className="Transition-opacity"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        padding: "2rem 1rem",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "1.6rem",
          gap: "1rem",
        }}
      >
        <Box
          className="Transition-2"
          sx={{
            cursor: "pointer",
            backgroundColor: "rgb(var(--accent-color0), 0.8)",
            color: "var(--background-color1)",
            overflow: "hidden",
            maxWidth: "3rem",
            gap: "0.5rem",
            alignItems: "center",
            padding: "0.5rem",

            "&:hover": {
              maxWidth: "20rem",
            },
          }}
          // onClick={() => navigate('/admin/estudo-individual/')}
        >
          <HighlightOffRoundedIcon
            sx={{
              width: "2rem",
              height: "2rem",
            }}
          />
          <span
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Parar teste
          </span>
        </Box>
      </div>

      <CustomText
        text={`Questão ${question_index + 1} de 10`}
        variant={"p"}
        style={{
          fontSize: {
            xs: "1.6rem",
            sm: "1.8rem",
            md: "2.2rem",
          },
          fontWeight: 700,
          width: "100%",
          padding: "1rem 0",
        }}
      />

      <div
        style={{
          flexWrap: "wrap",
          gap: "1rem",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, index) => (
          <div
            key={index}
            style={{
              height: "2rem",
              width: "2rem",
              borderRadius: "0.6rem",
              border: "1px solid rgba(0,0,0,0.2)",
              backgroundColor:
                question_index === index
                  ? "rgb(var(--accent-color0), 1)"
                  : "var(--background-color1)",
              justifyContent: "center",
              alignItems: "center",
              color:
                question_index === index
                  ? "var(--background-color1)"
                  : "var(--text-color2)",
              fontWeight: 700,
              fontSize: "1.2rem",
              cursor: "pointer",
            }}
            onClick={() => handleSetQuestionIndex(index)}
          >
            {item}
          </div>
        ))}
      </div>

      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          width: "100%",
          height: "0.5rem",
          borderRadius: "0.5rem",
          backgroundColor: "var(--background-color2)",
          "& .MuiLinearProgress-bar": {
            background:
              "linear-gradient(90deg, rgba(255,121,0,1) 0%, rgba(255,202,101,1) 100%)",
          },
        }}
      />
      <QuestionProvaItem
        question={{
          question_content:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget aliquam ultrices, nunc nisl ultricies nunc, quis aliquam nisl nunc eu nisl. Sed vitae nisl eget nisl aliquet ultrices. Sed vitae nisl eget nisl aliquet ultrices.",
          type: "objective",
          alternatives: [
            {
              alternative_content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            },
            {
              alternative_content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            },
            {
              alternative_content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            },
            {
              alternative_content:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
            },
          ],
          correct: "A",
          difficulty_level: "Fácil",
          degree: "Ensino Médio",
        }}
        index={0}
      />

      <div
        className={form_styles.customFormStyle}
        style={{
          justifyContent: "space-between",
          marginTop: "1rem",
          width: "100%",
        }}
      >
        <input
          type="submit"
          value="Questão anterior"
          style={{
            maxWidth: "max-content",
            background: "linear-gradient(310deg, rgba(52,109,143,1) 0%, rgba(113,116,178,1) 100%)",
          }}
          onClick={handlePreviousQuestion}
        />
        {question_index !== 9 && (
          <input
            type="submit"
            value="Pular questão"
            style={{
              maxWidth: "max-content",
              background: "linear-gradient(310deg, rgba(142,142,142,1) 0%, rgba(154,154,154,1) 100%)"
            }}
            onClick={handleNextQuestion}
          />
        )}
        <input
          type="submit"
          value={question_index === 9 ? "Finalizar" : "Próxima questão"}
          style={{
            maxWidth: "max-content",
            background: "linear-gradient(310deg, #348F50 0%, #71B280 100%)",
            color: "var(--text-color1)!important",
          }}
          onClick={handleNextQuestion}
        />
      </div>
    </Container>
  );
};

export default EstudarPage;
