import form_styles from "components/SpecificStyles/form.module.css";
import time_img from "assets/static/EstudoIndividual/time.svg";
import leaf_right from "assets/static/EstudoIndividual/leaf_right.svg";
import leaf_left from "assets/static/EstudoIndividual/leaf_left.svg";
import SearchIcon from "@mui/icons-material/Search";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Box, Checkbox } from "@mui/material";
import CustomText from "components/CustomText";
import { get } from "utilities/requests";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { formatTime } from "utilities/helpers";
import QuestionProvaItem from "pages/auth/Provas/view/Question";

const ProvaFinalizadaPage = () => {
  const [questoes, setQuestoes] = useState([
    {
      _id: "1",
      question_content: "Qual a capital do Brasil?",
      type: "objective",
      alternatives: [
        {
          _id: "1",
          content: "Brasília",
        },
        {
          _id: "2",
          content: "São Paulo",
        },
        {
          _id: "3",
          content: "Rio de Janeiro",
        },
        {
          _id: "4",
          content: "Belo Horizonte",
        },
      ],
      correct: "a",
      difficulty_level: "Difícil",
      degree: "Ensino Médio",
    },
    {
      _id: "2",
      question_content: "Qual é a velocidade de um avião sem asa e sem motor?",
      type: "objective",
      alternatives: [
        {
          _id: "1",
          content: "100km/h",
        },
        {
          _id: "2",
          content: "200km/h",
        },
        {
          _id: "3",
          content: "300km/h",
        },
        {
          _id: "4",
          content: "Eu sei la porra",
        },
      ],
      correct: "d",
      difficulty_level: "Fácil",
      degree: "Ensino Médio",
    },
  ]);
  const [cronometro, setCronometro] = useState(4680); // segundos
  const [acertos_count, setAcertosCount] = useState(7);
  const [pulos_count, setPulosCount] = useState(1);
  const [erros_count, setErrosCount] = useState(2);

  const series = [acertos_count, pulos_count, erros_count];
  const options = {
    chart: {
      type: "polarArea",
      foreColor: "var(--text-color1)",
      width: "100%",
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ["var(--text-color2)", "var(--text-color2)", "red"],
        fontSize: "12px",
        fontWeight: "bold",
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
      },
      offsetX: 30,
    },
    labels: ["Acertos", "Pulos", "Erros"],
    fill: {
      opacity: 0.7,
    },
    stroke: {
      colors: ["#fff"],
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      show: false,
    },
    legend: {
      position: "bottom",
    },
  };
  return (
    <Box
      className="Transition-opacity"
      sx={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        gap: "2rem",
        flexDirection: "column",
        padding: {
          xs: "2rem 1rem",
          sm: "2rem 1rem",
          md: "3rem 2rem",
        },
      }}
    >
      <Box
        sx={{
          background:
            "linear-gradient(180deg, var(--background-color1) 0%, var(--background-color0) 100%)",
          justifyContent: "center",
          alignItems: "center",
          padding: {
            xs: "3rem 0",
            sm: "3rem 0",
            md: "2rem 0",
          },
          position: "relative",
          gap: "2rem",
          borderRadius: "2rem",
          marginBottom: "2rem",
        }}
      >
        <img
          src={leaf_right}
          alt="leaf"
          style={{
            width: "10vw",
            minWidth: "7rem",
            maxWidth: "11rem",
            position: "absolute",
            bottom: "0rem",
            right: "0rem",
            borderRadius: "2rem",
          }}
        />
        <img
          src={leaf_left}
          alt="leaf"
          style={{
            width: "10vw",
            minWidth: "7rem",
            maxWidth: "11rem",
            position: "absolute",
            bottom: "0rem",
            left: "0rem",
            borderRadius: "2rem",
          }}
        />

        <Box
          className={form_styles.customFormStyle}
          sx={{
            flexDirection: "column",
            gap: "0.2rem",
            // height: "40rem",
            marginBottom: "2rem",
            alignItems: "center",
            padding: "1rem",
          }}
        >
          <CustomText
            text="Prova finalizada!"
            style={{
              fontSize: {
                xs: "2.6rem",
                sm: "2.8rem",
                md: "3.2rem",
              },
              fontWeight: 600,
              textAlign: "center",
              marginBottom: {
                xs: "1rem",
                sm: "1rem",
                md: "0",
              },
            }}
          />
          <CustomText
            text="Geramos um relatório com o seu desempenho na prova."
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1.1rem",
                md: "1.2rem",
              },
              fontWeight: 600,
              marginBottom: "1rem",
              textAlign: "center",
            }}
          />
        </Box>
      </Box>

      <div
        style={{
          flexWrap: "wrap",
          gap: "2rem",
        }}
      >
        <Box
          sx={{
            backgroundColor: "var(--background-color1)",
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "2rem 1rem",
              sm: "2rem 1.4rem",
              md: "2rem 1.6rem",
            },
            gap: "2rem",
            borderRadius: "2rem",
            flexDirection: "column",
            flexGrow: 1,
            width: "30rem",
          }}
        >
          <CustomText
            text="Desempenho"
            style={{
              fontSize: {
                xs: "1.6rem",
                sm: "1.8rem",
                md: "2rem",
              },
              fontWeight: 600,
              textAlign: "center",
              marginBottom: {
                xs: "1rem",
                sm: "1rem",
                md: "0",
              },
            }}
          />
          <ReactApexChart options={options} series={series} type="donut" />

          <CustomText
            text={`De ${
              acertos_count + erros_count + pulos_count
            } questões, você acertou ${acertos_count}, pulou ${pulos_count} e errou ${erros_count}.`}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.2rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
          <CustomText
            text={"É isso aí, continue estudando! 👍"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.2rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
        </Box>

        <Box
          sx={{
            backgroundColor: "var(--background-color1)",
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "2rem 1rem",
              sm: "2rem 1.4rem",
              md: "2rem 1.6rem",
            },
            gap: "2rem",
            borderRadius: "2rem",
            flexDirection: "column",
            flexGrow: 1,
            width: "30rem",
          }}
        >
          <CustomText
            text={`Tempo`}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1.6rem",
                sm: "1.8rem",
                md: "2rem",
              },
              fontWeight: 600,
              textAlign: "center",
              marginBottom: {
                xs: "1rem",
                sm: "1rem",
                md: "0",
              },
            }}
          />
          <img
            src={time_img}
            alt="Ícone de aviso"
            style={{
              width: "14rem",
              height: "14rem",
            }}
          />
          <CustomText
            text={`Você levou ${formatTime(
              cronometro
            )} segundos para finalizar a prova.`}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.2rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
          <CustomText
            text={`Média ${formatTime(
              cronometro / (acertos_count + erros_count + pulos_count)
            )} segundos para responder cada questão.`}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "var(--background-color1)",
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: "2rem 1rem",
              sm: "2rem 1.4rem",
              md: "2rem 1.6rem",
            },
            gap: "2rem",
            borderRadius: "2rem",
            flexDirection: "column",
            flexGrow: 1,
            width: "30rem",
          }}
        >
          <CustomText
            text={`Tempo`}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1.6rem",
                sm: "1.8rem",
                md: "2rem",
              },
              fontWeight: 600,
              textAlign: "center",
              marginBottom: {
                xs: "1rem",
                sm: "1rem",
                md: "0",
              },
            }}
          />
          <img
            src={time_img}
            alt="Ícone de aviso"
            style={{
              width: "14rem",
              height: "14rem",
            }}
          />
          <CustomText
            text={`Você levou ${formatTime(
              cronometro
            )} segundos para finalizar a prova.`}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1.2rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
          <CustomText
            text={`Média ${formatTime(
              cronometro / (acertos_count + erros_count + pulos_count)
            )} segundos para responder cada questão.`}
            variant={"p"}
            style={{
              fontSize: {
                xs: "1rem",
                sm: "1rem",
                md: "1rem",
              },
              fontWeight: 600,
              textAlign: "center",
            }}
          />
        </Box>
      </div>

      <Box
        sx={{
          padding: {
            xs: "2rem 1rem",
            sm: "2rem 1.4rem",
            md: "2rem 1.6rem",
          },
          gap: "2rem",
          flexDirection: "column",
        }}
      >
        <CustomText
          text="Questões"
          style={{
            fontSize: {
              xs: "1.6rem",
              sm: "1.8rem",
              md: "2rem",
            },
            fontWeight: 600,
            textAlign: "center",
            marginBottom: {
              xs: "1rem",
              sm: "1rem",
              md: "0",
            },
          }}
        />
        <Box
          sx={{
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          {questoes.map((questao, index) => (
            <QuestionProvaItem
              key={index}
              index={index}
              question={{ question: questao }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProvaFinalizadaPage;
