import ReactTyped from "react-typed";

export default function ReactTyping({
  setSlideIndex,
}) {
  return (
    <ReactTyped
      strings={[
        "Modele sua <u>experiência de aprendizado</u> de acordo com suas necessidades.",
        "Desenvolva planos de ensino para atingir seus <u>objetivos acadêmicos</u>.",
        "Enfrente <u>desafios reais</u> com questões de vestibulares, concursos e mais.",
        "Ajuste os filtros e crie <u>avaliações personalizadas</u> para suas necessidades.",
        "Facilite sua vida, ganhe tempo e aprenda mais. Venha para a <u>On Test</u>!",
      ]}
      typeSpeed={20}
      backSpeed={8}
      backDelay={6000}
      preStringTyped={(number) => setSlideIndex(number)}
      smartBackspace
      loop
      style={{
        fontSize: "1.4rem",
        zIndex: "2",
      color: "var(--text-color1)",
      }}
    />
  );
}
