import { formatTime } from "utilities/helpers";

export const formatCorrectDate = (application) => {
  const init = {
    date: new Date(application.init_test),
    time: new Date(application.time_init_test),
  };
  const end = {
    date: new Date(application.end_test),
    time: new Date(application.time_end_test),
  };

  const init_date = new Date(
    init.date.getFullYear(),
    init.date.getMonth(),
    init.date.getDate(),
    init.time.getHours(),
    init.time.getMinutes()
  );
  const end_date = new Date(
    end.date.getFullYear(),
    end.date.getMonth(),
    end.date.getDate(),
    end.time.getHours(),
    end.time.getMinutes()
  );

  return {
    init_date,
    end_date,
  };
};

export const diffDateInSeconds = (date1, date2) => {
  if (date1 && date2) {
    const diff = date2.getTime() - date1.getTime();
    return formatTime(diff / 1000);
  }
};
