/**
 * @param {String} title
 * @param {Array} list
 * @param {Object} search
 * @param {ReactNode} children
 * @param {Object} parent_style
 *
 * @example
 *
 * <ComponentList
 *  title="Provas"
 *  list={array_example}
 *  search={handleSearch}
 * >
 *  { array_example.map ... }
 * </ComponentList>
 **/

import CustomInput from "components/CustomInput";
import SearchIcon from "@mui/icons-material/Search";
import form_styles from "components/SpecificStyles/form.module.css";
import CustomText from "components/CustomText";

const ComponentList = ({
  title,
  list,
  search,
  parent_style,
  children,
  student_application,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: "80%",
        width: "100%",
        overflowY: "auto",
        gap: "0.6rem",
        padding: "1rem",
        // paddingRight: "0",
        ...parent_style,
      }}
      className={form_styles.customFormStyle}
    >
      {/* <CustomInput
        placeholder="Buscar"
        icon={SearchIcon}
        value={search.value}
        onChange={search.onChange}
        helperText="Insira o nome do que deseja buscar"
      /> */}

      <CustomText
        text={student_application?.exam_application?.name}
        variant={"p"}
        lines={1}
        style={{
          fontSize: "1rem",
          fontWeight: 700,
          paddingLeft: "0.6rem",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflowY: "auto",
          gap: "0.6rem",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ComponentList;
