import FlagIcon from "@mui/icons-material/Flag";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function ReviseQuestionItem({
  ActionButton,
  is_responding,
  setIsResponding,
  new_user_message,
  setNewUserMessage,
  handleSubmit
}) {
  return (
    <div
      style={{
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color1)",
      }}
    >
      {is_responding && (
        <div
          style={{
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <textarea
            style={{
              backgroundColor: "var(--background-color2)",
              borderRadius: "0.5rem",
              padding: "1rem",
              border: "none",
              fontFamily: "var(--Poppins)",
              resize: "vertical",
              fontSize: "1rem",
            }}
            placeholder="Explique o motivo da revisão. Min. 10 caracteres."
            value={new_user_message}
            onChange={(e) => setNewUserMessage(e.target.value)}
          />
          <div
            style={{
              gap: "1rem",
            }}
          >
            <ActionButton
              text="Enviar"
              Icon={FlagIcon}
              color="rgb(var(--accent-color0)"
              onClick={handleSubmit}
            />
            <ActionButton
              text="Cancelar"
              Icon={HighlightOffIcon}
              color="rgb(var(--accent-color1)"
              onClick={() => setIsResponding(false)}
            />
          </div>
        </div>
      )}

      {!is_responding && (
        <ActionButton
          hidden={is_responding}
          text="Pedir revisão"
          Icon={FlagIcon}
          color="rgb(var(--accent-color0)"
          onClick={() => setIsResponding(true)}
        />
      )}
    </div>
  );
}
