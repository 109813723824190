import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  Switch,
  Tooltip,
} from "@mui/material";
import CustomText from "components/CustomText";
import MobileButton from "components/MobileButton";
import form_styles from "components/SpecificStyles/form.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import LockIcon from '@mui/icons-material/Lock';
import EmailIcon from '@mui/icons-material/Email';
import CustomInput from "components/CustomInput";
import { post, put } from "utilities/requests";
import { toast } from "react-toastify";
import swal from "sweetalert";
import user_avatar from "assets/static/default.png";

const EditarPerfilPage = ({
  userinfo,
  open_profile_edit_modal,
  setOpenProfileEditModal,
  firstLogin = false,
}) => {
  const [username, setUsername] = useState("");
  const [avatar, setAvatar] = useState("https://picsum.photos/300");
  const [avatar_base64, setAvatarBase64] = useState("");
  const [email, setEmail] = useState("");
  const [editing_password, setEditingPassword] = useState(false);

  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const handleSetUserinfo = async (e) => {
    e.preventDefault();

    if (username === "") {
      return toast.warning("O campo nome não pode estar vazio!");
    }

    if (editing_password) {
      if (password !== confirm_password) {
        return toast.warning("As senhas não coincidem!");
      }

      if (password.length < 6 || password === "") {
        return toast.warning("A senha deve ter no mínimo 6 caracteres!");
      }
    }

    const formData = new FormData();
    formData.append("email", email);
    formData.append("name", username);
    formData.append("avatar", avatar);
    formData.append("role", 'student');
    if (editing_password) {
      formData.append("password", password);
    }

    const res = await put(`users/me`, formData);

    if (res?.status === 200) {
      swal({
        title: "Sucesso!",
        text: "Seus dados foram atualizados!",
        icon: "success",
        button: "Ok",
      }).then(() => {
        window.location.reload(true);
      });
    } else {
      swal({
        title: "Erro!",
        text: "Não foi possível atualizar seus dados!",
        icon: "error",
        button: "Ok",
      });
    }

    // if (firstLogin) {
    //   return await post(
    //     `change_password_first_login`,
    //     JSON.stringify({
    //       password: password,
    //     })
    //   ).then(() => {
    //     window.location.reload(true);
    //   });
    // } else {
    //   return await put(`users/me`, {
    //     name: username,
    //     password,
    //   });
    // }
    // const { data } = await get(`questions/tags?search=${search}&page=1`);
  };

  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    setAvatar(file);

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setAvatarBase64(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (userinfo) {
      setUsername(userinfo.name);
      setAvatar(userinfo.avatar);
      setEmail(userinfo.email);
    }
  }, [userinfo]);

  return (
    <Modal
      open={open_profile_edit_modal}
      onClose={() => setOpenProfileEditModal(false)}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          fontFamily: "Sans-serif",
          display: "flex",
          flexDirection: "column",
          maxWidth: "46rem",
          maxHeight: "98vh",
          width: "100%",
          gap: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              top: "1rem",
              right: "1rem",
              justifyContent: "space-between",
              padding: "1rem 1.6rem",
              backgroundColor: "var(--background-color1)",
              borderRadius: "0",
              alignItems: "center",
            }}
          >
            <CustomText
              text="Seu perfil"
              variant="h4"
              style={{ textAlign: "center", fontSize: "1.6rem" }}
            />
            <MobileButton
              icon={CloseIcon}
              action={() => setOpenProfileEditModal(false)}
            />
          </div>

          <Box
            sx={{
              width: "100%",
            }}
          >
            <div
              style={{
                gap: "2rem",
                position: "relative",
                justifyContent: "space-between",
                height: "16rem",
                width: "100%",
              }}
            >
              <img
                src={"https://picsum.photos/1200/300"}
                alt={userinfo.name}
                style={{
                  width: "100%",
                  height: "60%",
                  objectFit: "cover",
                  borderRadius: "0",
                  position: "absolute",
                  zIndex: 1,
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  gap: "0",
                  padding: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "2rem",
                  },
                  zIndex: 10,
                  alignItems: "end",
                  position: "relative",
                  width: "100%",
                }}
              >
                {/* <img
                  src={"https://picsum.photos/300"}
                  alt={userinfo.name}
                  style={{
                    width: "20rem",
                    height: "20rem",
                    maxWidth: "10rem",
                    maxHeight: "10rem",
                    borderRadius: "50%",
                    border: "0.4rem solid var(--background-color0)",
                  }}
                /> */}
                <label
                  htmlFor="avatar"
                  style={{
                    width: "20rem",
                    height: "20rem",
                    maxWidth: "10rem",
                    maxHeight: "10rem",
                    borderRadius: "50%",
                    border: "0.4rem solid var(--background-color0)",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={avatar_base64 ? avatar_base64 : avatar || user_avatar}
                    alt={userinfo.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <input
                    type="file"
                    name="avatar"
                    id="avatar"
                    style={{ display: "none" }}
                    onChange={handleAvatarChange}
                  />
                </label>

                <Box
                  component="input"
                  type="text"
                  placeholder="Seu nome"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  sx={{
                    outline: "none",
                    border: "none",
                    borderRadius: "0.5rem",
                    width: "100%",
                    color: "var(--11)",

                    fontSize: "2rem",
                    fontWeight: 600,
                    marginBottom: "1rem",
                    padding: "0.2rem 1rem",

                    backgroundColor: "var(--background-color2)",
                    "&:focus": {},
                  }}
                />
                {/* <Tooltip
                  title="Clique em seu nome para editá-lo."
                  placement="top"
                >
                  <BorderColorIcon
                    sx={{
                      marginBottom: "1.6rem",
                      color: "var(--text-color1)",
                      marginLeft: "1rem",
                    }}
                  />
                </Tooltip> */}
              </Box>
            </div>
          </Box>

          <CustomText
            text="Dados de acesso"
            variant="p"
            style={{
              fontSize: "1.4rem",
              fontWeight: 600,
              textAlign: "center",
              marginTop: "0.8rem",
            }}
          />

          <Box
            component={"form"}
            className={form_styles.customFormStyle}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: {
                xs: "0.8rem 2rem 2rem 2rem",
                sm: "0.8rem 2rem 2rem 2rem",
                md: "0.8rem 3rem 3rem 3rem",
              },
            }}
            onSubmit={handleSetUserinfo}
          >
            <CustomText
              text="E-mail"
              variant="p"
              style={{ fontSize: "1.4rem", fontWeight: 600 }}
            />

            <div
              style={{
                flexDirection: "column",
                width: "100%",
              }}
            >
              <CustomInput
                type={"email"}
                // disabled={email_visibility}
                disabled={true}
                placeholder="E-mail"
                icon={EmailIcon}
                value={email}
                helperText={"Este é o seu e-mail de login."}
                onChange={(e) => setEmail(e)}
                parentStyle={{
                  width: "100%",
                }}
              />

              <span
                style={{
                  fontSize: "1.2rem",
                  color: "var(--text-color1)",
                  marginTop: "0.8rem",
                }}
              >
                Você não pode alterar seu e-mail.
              </span>
            </div>

            <FormGroup
              sx={{
                marginTop: "2rem",
                marginRight: "1.4rem",
                padding: "0 0.8rem",
              }}
            >
              <FormControlLabel
                style={{
                  padding: "0.4rem",
                  paddingRight: "1rem",
                  width: "max-content",
                }}
                control={
                  <Switch
                    checked={editing_password}
                    onChange={() => setEditingPassword(!editing_password)}
                  />
                }
                label="Alterar senha"
              />
            </FormGroup>

            {editing_password && (
              <>
                <CustomText
                  text="Senha"
                  variant="p"
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: 600,
                    marginTop: "2rem",
                  }}
                />

                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <CustomInput
                    type={"password"}
                    placeholder="Nova senha"
                    icon={LockIcon}
                    value={password}
                    onChange={(e) => setPassword(e)}
                    parentStyle={{
                      width: "100%",
                    }}
                  />
                </div>

                <CustomText
                  text="Confirme a senha"
                  variant="p"
                  style={{
                    fontSize: "1.4rem",
                    fontWeight: 600,
                    marginTop: "2rem",
                  }}
                />

                <div
                  style={{
                    width: "100%",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <CustomInput
                    type={"password"}
                    placeholder="Confirme a nova senha"
                    icon={LockIcon}
                    value={confirm_password}
                    onChange={(e) => setConfirmPassword(e)}
                    parentStyle={{
                      width: "100%",
                    }}
                  />
                </div>
              </>
            )}

            <div
              style={{
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <CustomText
                text="Os campos alterados serão salvos."
                variant="p"
                style={{
                  fontSize: "1.2rem",
                  textAlign: "center",
                  marginTop: "2rem",
                }}
              />
              <input
                type="submit"
                value={"Salvar"}
                style={{
                  maxWidth: "max-content",
                  background:
                    "linear-gradient(310deg, #348F50 0%, #71B280 100%)",
                  color: "var(--text-color1)!important",
                  alignSelf: "flex-end",
                  marginTop: "2rem",
                }}
              />
            </div>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditarPerfilPage;
