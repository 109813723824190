import { Box, Icon } from "@mui/material";

const MobileButton = ({
  icon,
  parentStyle = {},
  iconStyle = {},
  action = () => {},
}) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        cursor: "pointer",
        
        "& .mobile-button-ghost": {
          opacity: 0,
        },
        "&:hover": {
          "& .mobile-button-ghost": {
            opacity: 1,
          },
        },
        "&:active": {
          "& .mobile-button-ghost": {
            opacity: 1,
          },
        },

        ...parentStyle,
      }}
      onClick={action}
    >
      <div
        className="mobile-button-ghost"
        style={{
          position: "absolute",
          width: iconStyle.fontSize || "2rem",
          height: iconStyle.fontSize || "2rem",
          borderRadius: "50%",
          backgroundColor: "var(--background-color2)",
          zIndex: 1,
          transform: "scale(1.5)",
        }}
      />
      <Icon
        style={{
          fontSize: "2rem",
          color: "var(--text-color1)",
          zIndex: 2,
          ...iconStyle,
        }}
        component={icon}
      />
    </Box>
  );
};

export default MobileButton;
