import { Box, Modal } from "@mui/material";
import form_styles from "components/SpecificStyles/form.module.css";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  diffDateInSeconds,
  formatCorrectDate,
} from "utilities/application/formatCorrectDate";
import { formatDate } from "utilities/helpers";
import { get } from "utilities/requests";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

export default function AplicacoesCardsItem({
  application,
  server_time,
  setServerTime,
}) {
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [open_instructions_modal, setOpenInstructionsModal] = useState(false);
  const instructions = [
    `A partir de agora, você terá ${diffDateInSeconds(
      server_time,
      formatCorrectDate(application).end_date
    )} para responder o teste.`,
    "Não será possível pausar o teste, então, certifique-se de estar em um ambiente tranquilo.",
    "Ao marcar uma questão, o seu progresso será salvo automaticamente.",
    "Caso você saia da página, você poderá retornar para continuar o teste.",
    "Ao finalizar o teste, clique em 'Finalizar' ou aguarde o tempo acabar.",
    "Uma vez que o tempo acabar, o teste será finalizada automaticamente e não será possível retornar.",
  ];

  const checkIfApplicationIsAvailable = async () => {
    let toast_id = toast.loading("Verificando...");

    get("student_application/get-current-time").then((res) => {
      if (res.status === "success") {
        const now = new Date(res.time);
        setServerTime(new Date(res.time));
        const { init_date, end_date } = formatCorrectDate(application);

        // if is Presencial, then redirect to the application page
        if (application.type === "not_online") {
          if (now > end_date) {
            toast.dismiss(toast_id);
            navigate(`/admin/provas/${application._id}`);
          } else {
            toast.update(toast_id, {
              render: "Não disponível para visualização",
              type: "error",
              isLoading: false,
              autoClose: 4000,
            });
          }
          return;
        }

        if (now < init_date) {
          toast.update(toast_id, {
            render: "Aplicação não iniciada",
            type: "info",
            isLoading: false,
            autoClose: 4000,
          });
        } else if (now > end_date) {
          toast.dismiss(toast_id);
          navigate(`/admin/provas/${application._id}`);
        } else {
          toast.dismiss(toast_id);
          setOpenInstructionsModal(true);
        }
      }
    });
  };

  useEffect(() => {
    const { init_date, end_date } = formatCorrectDate(application);

    if (server_time < init_date) {
      setStatus("Não iniciado");
    } else if (server_time > end_date) {
      setStatus("Encerrado");
    } else {
      setStatus("Em andamento");
    }
  }, [server_time]);
  return (
    <>
      <Modal
        open={open_instructions_modal}
        onClose={() => setOpenInstructionsModal(false)}
      >
        <Box
          className={form_styles.customFormStyle}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: "44rem",
            padding: "2rem 4rem",
            maxHeight: "98vh",
            backgroundColor: "white",
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
            display: "flex",
            flexDirection: "column",
            gap: "3vh",
          }}
        >
          <CustomText
            text="Você está prestes a iniciar a aplicação"
            variant="h4"
            style={{
              textAlign: "center",
              fontSize: "1.8rem",
            }}
          />

          <CustomText
            text="Leia atentamente as instruções abaixo:"
            variant="p"
            style={{ textAlign: "center", fontSize: "1.2rem" }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              fontSize: "1.2rem",
              color: "var(--text-color2)",
              lineHeight: "1.6rem",
            }}
          >
            {instructions.map((instruction, index) => (
              <CustomText
                key={index}
                text={`${index + 1}. ${instruction}`}
                variant="p"
                style={{
                  textAlign: "justify",
                }}
              />
            ))}
          </div>

          <input
            type="submit"
            value="Iniciar"
            style={{
              maxWidth: "12rem",
            }}
            onClick={() => {
              navigate(`responder/${application._id}`);
            }}
          />
        </Box>
      </Modal>
      <div
        style={{
          flexDirection: "column",
          gap: "1rem",
          padding: "2rem 1rem",
          borderRadius: "0.5rem",
          flexGrow: 1,
          flexBasis: "30rem",
          backgroundColor: "var(--background-color1)",
          position: "relative",
        }}
        onClick={checkIfApplicationIsAvailable}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
          }}
        >
          <CustomText
            text={status}
            variant={"p"}
            style={{
              background:
                status === "Não iniciado"
                  ? "#6592db"
                  : status === "Encerrado"
                  ? "#e74c3c"
                  : "rgba(var(--accent-color0), 0.7)",
              color: "var(--background-color0)",
              fontSize: "1rem",
              fontWeight: 700,
              padding: "0.1rem 1.2rem",
              borderRadius: "0 0.8rem 0 0.6rem",
              display: "flex",
              alignItems: "center",
              gap: "0.4rem",
              width: "fit-content",
              whiteSpace: "nowrap",
            }}
          />
        </div>

        <CustomText
          text={application.name}
          variant={"h6"}
          lines={2}
          style={{
            fontSize: {
              xs: "1.4rem",
              sm: "1.6rem",
              md: "1.8rem",
            },
            fontWeight: 700,
          }}
        />

        <CustomText
          text={application.description}
          variant={"p"}
          lines={4}
          style={{
            fontSize: {
              xs: "1.2rem",
              sm: "1.4rem",
              md: "1.6rem",
            },
            fontWeight: 500,
            textAlign: "justify",
          }}
        />

        <div
          style={{
            flexDirection: "column",
            gap: "0.6rem",
          }}
        >
          <span
            style={{
              display: "flex",
              gap: "1rem",
              fontSize: "1rem",
              fontWeight: 700,
              alignItems: "center",
              marginBottom: "0.4rem",
              whiteSpace: "nowrap",
            }}
          >
            Início:
            <span
              style={{
                background: "rgba(var(--accent-color1), 0.7)",
                color: "var(--background-color0)",
                padding: "0.4rem 0.8rem",
                borderRadius: "1rem",
              }}
            >
              {formatDate(formatCorrectDate(application).init_date, true)}
            </span>
          </span>
          <span
            style={{
              display: "flex",
              gap: "1rem",
              fontSize: "1rem",
              fontWeight: 700,
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            Fim:
            <span
              style={{
                background: "rgba(var(--accent-color0), 0.7)",
                color: "var(--background-color0)",
                padding: "0.4rem 0.8rem",
                borderRadius: "1rem",
              }}
            >
              {formatDate(formatCorrectDate(application).end_date, true)}
            </span>
          </span>
        </div>

        <button
          style={{
            display: "flex",
            gap: "0.5rem",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "1.1rem",
            backgroundColor: "rgba(var(--accent-color1), 0.6)",
            width: "fit-content",
            fontWeight: 700,
            padding: "0.5rem 1rem",
            borderRadius: "0.5rem",
            color: "white",
            alignSelf: "flex-end",
          }}
        >
          Ver detalhes
          <MoreHorizIcon
            style={{
              fontSize: "1.5rem",
            }}
          />
        </button>
      </div>
    </>
  );
}
