import { Icon, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import form_styles from "components/SpecificStyles/form.module.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get, post } from "utilities/requests";
import { toast } from "react-toastify";
import LockIcon from "@mui/icons-material/Lock";
import CustomInput from "components/CustomInput";
import EmailIcon from "@mui/icons-material/Email";
import CircularProgress from "@mui/material/CircularProgress";

const LoginPage = () => {
  let navigate = useNavigate();

  const [is_loading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("student1@enterscience.com.br");
  const [password, setPassword] = useState("12345678");
  const [showPassword, setShowPassword] = useState(false);

  const handleGetUserInfo = async () => {
    const res = await get(`me`);
    return res;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let form = new FormData();
    form.append("email", email);
    form.append("password", password);

    const res = await post("auth", form);

    if (res?.httpCode === 200) {
      localStorage.setItem("token", res.token);
      handleGetUserInfo().then((data) => {
        if (data.data.status === undefined || data.data.status === "CREATED") {
          localStorage.setItem("first_access", true);
          navigate("/primeiro-acesso");
        } else {
          toast.success("Login realizado com sucesso!");
          navigate("/admin/aplicacoes");
          localStorage.setItem("user", JSON.stringify(data.data));
        }
      });
    } else {
      toast.error(res.message || "Erro ao fazer login. Contate o suporte.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      handleGetUserInfo().then((data) => {
        if (data.data.status === undefined || data.data.status === "") {
          navigate("/primeiro-acesso");
        } else {
          navigate("/admin/aplicacoes");
          // navigate("/admin/dashboard");
        }
      });
    }
  }, []);

  return (
    <CustomSection
      style={{
        margin: "0 auto",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Login
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "30rem",
          }}
        >
          Faça login para continuar.
        </Typography>

        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
            width: "100%",
            maxWidth: "30rem",
          }}
          className={form_styles.customFormStyle}
          onSubmit={handleSubmit}
        >
          <CustomInput
            type="email"
            placeholder="E-mail"
            icon={EmailIcon}
            value={email}
            onChange={setEmail}
            required
          />

          <CustomInput
            type={"password"}
            placeholder="Senha"
            icon={LockIcon}
            value={password}
            onChange={setPassword}
          />

          {/* <div
            style={{
              position: "relative",
            }}
          >
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Icon
              sx={{
                position: "absolute",
                right: "1rem",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                opacity: 0.7,
                "&:hover": {
                  opacity: 1,
                },
              }}
              onClick={() => setShowPassword(!showPassword)}
              component={showPassword ? VisibilityOffIcon : VisibilityIcon}
            />
          </div> */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Typography
              variant="p"
              sx={{
                fontSize: "1rem",
                // textAlign: "center",
                fontWeight: "normal",
              }}
            >
              Esqueceu a senha? <a href="#">Clique aqui</a>.
            </Typography>
            {is_loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.5rem 3rem",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <input
                type="submit"
                value="Entrar"
                style={{
                  maxWidth: "12rem",
                }}
              />
            )}
          </div>
        </form>
      </div>
    </CustomSection>
  );
};

export default LoginPage;
