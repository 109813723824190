import { Box, LinearProgress } from "@mui/material";
import CustomText from "components/CustomText";
import QuestionProvaItem from "./Question";
import { useEffect, useState } from "react";
import { post } from "utilities/requests";
import { toast } from "react-toastify";
import AplicacoesResponderFormControllers from "./FormControllers";
import ResponderFormIndexSelector from "./IndexSelector";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

export default function AplicacoesResponderForm({
  student_application,
  application_id,
  selected_exam,
  setSelectedExam,
  all_questions,
  setAllQuestions,
}) {
  const navigate = useNavigate();
  const [question_index, setQuestionIndex] = useState(0);

  const handleSaveAnswer = async (
    question,
    alternative,
    essay_answer = null
  ) => {
    let form = new FormData();
    form.append("exam_id", selected_exam._id);
    form.append("question_id", question._id);
    form.append("student_application_id", student_application._id);

    if (essay_answer) {
      form.append("essay_answer", essay_answer);
    } else {
      form.append("alternative_id", alternative._id);
    }

    let id = toast.loading("Salvando resposta...");

    const res = await post("student_application/save-answer-choice", form);
    if (res.httpCode === 200) {
      toast.update(id, {
        render: "Resposta salva com sucesso!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      // add checked inside choiced alternative
      let new_questions = [...all_questions];
      new_questions[question_index].alternatives.forEach((item) => {
        if (item._id === alternative._id) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      setAllQuestions(new_questions);
    } else {
      toast.dismiss(id);
      if (res.message.status === 401) {
        // means that application is not available by date
        swal({
          title: "Aplicação não disponível",
          text: "A aplicação não está disponível para ser respondida.",
          icon: "error",
          button: "Ok",
        }).then(() => {
          navigate("/admin/aplicacoes");
        });
      }
    }
  };

  useEffect(() => {
    setQuestionIndex(0);
  }, [selected_exam]);

  return (
    <>
      <CustomText
        text={`Questão ${question_index + 1}`}
        variant={"p"}
        style={{
          fontSize: {
            xs: "1.6rem",
            sm: "1.8rem",
            md: "2.2rem",
          },
          fontWeight: 700,
          width: "100%",
          padding: "1rem 0",
        }}
      />

      <div
        style={{
          flexWrap: "wrap",
          gap: "1rem",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
        {all_questions.map((question, index) => (
          <ResponderFormIndexSelector
            key={index}
            {...{
              question,
              index,
              question_index,
              setQuestionIndex,
            }}
          />
        ))}
      </div>

      <LinearProgress
        variant="determinate"
        value={((question_index + 1) / all_questions.length) * 100}
        sx={{
          width: "100%",
          height: "0.5rem",
          borderRadius: "0.5rem",
          backgroundColor: "var(--background-color2)",
          "& .MuiLinearProgress-bar": {
            background:
              "linear-gradient(90deg, rgba(255,121,0,1) 0%, rgba(255,202,101,1) 100%)",
            transition: "all 0.8s cubic-bezier(0,.68,.6,1)",
          },
        }}
      />

      {all_questions[question_index].type === "set" ? (
        <div
          style={{
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              gap: "1rem",
              backgroundColor: "var(--background-color1)",
              width: "100%",
              padding: {
                xs: "0.6rem 0.8rem",
                sm: "1rem 1.4rem",
                md: "1.6rem 2rem",
              },
            }}
          >
            <CustomText
              variant="span"
              text={"Leia o enunciado e responda"}
              style={{
                fontSize: "1.4rem",
                fontWeight: 600,
                textAlign: "justify",
                width: "100%",
              }}
            />
            <CustomText
              variant="span"
              text={all_questions[question_index].question_content}
              style={{
                fontSize: "1.2rem",
                textAlign: "justify",
                width: "100%",
              }}
            />
          </Box>
          {all_questions[question_index].questions.map((question, index) => {
            return (
              <QuestionProvaItem
                {...{
                  question,
                  question_index: index,
                  handleSaveAnswer,
                  all_questions,
                  setAllQuestions,
                }}
              />
            );
          })}
        </div>
      ) : (
        <QuestionProvaItem
          {...{
            question: all_questions[question_index],
            question_index,
            handleSaveAnswer,
            all_questions,
            setAllQuestions,
          }}
        />
      )}

      <AplicacoesResponderFormControllers
        {...{
          student_application,
          selected_exam,
          setSelectedExam,
          question_index,
          setQuestionIndex,
          all_questions,
        }}
      />
    </>
  );
}
