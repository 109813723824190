import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade} from "swiper";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

// const img1 = 'https://picsum.photos/500';
// const img2 = 'https://picsum.photos/501';
// const img3 = 'https://picsum.photos/502';
// const img4 = 'https://picsum.photos/503';
// const img5 = 'https://picsum.photos/504';

import img1 from 'assets/static/home/slide_0.jpg';
import img2 from 'assets/static/home/slide_1.jpg';
import img3 from 'assets/static/home/slide_2.jpg';
import img4 from 'assets/static/home/slide_3.jpg';
import img5 from 'assets/static/home/slide_4.jpg';

const SwiperCarousel = ({
  slide_index,
}) => {
  const img_style = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    maskImage: "linear-gradient(to top, transparent 0%, black 30%)",
    WebkitMaskImage: "linear-gradient(to top, transparent 0%, black 30%)",
  };

  const swiperRef = useRef(null);

  const handleSetSlide = (index) => {
    if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
      swiperRef.current.swiper.slideTo(index);
    }
  }

  useEffect(() => {
    handleSetSlide(slide_index);
  }, [slide_index]);

  return (
    <>
      <Swiper
        ref={swiperRef}
        style={{
          width: "100%",
          height: "70vh",
          borderRadius: "4rem 4rem 0 0",
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 10px 50px",
        }}
        modules={[EffectFade, Autoplay]}
        // navigation={true}
        // autoplay={{
        //   delay: 5000,
        // }}
        speed={1500}
        slidesPerView={1}
        effect={"fade"}
        fadeEffect={{ crossFade: true }}
      >
        <SwiperSlide>
          <img
            style={img_style}
            src={img1}
            alt="Imagem do Carousel"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            style={img_style}
            src={img2}
            alt="Imagem do Carousel"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            style={img_style}
            src={img3}
            alt="Imagem do Carousel"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            style={img_style}
            src={img4}
            alt="Imagem do Carousel"
          />
        </SwiperSlide>
        <SwiperSlide>
          <img
            style={img_style}
            src={img5}
            alt="Imagem do Carousel"
          />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default SwiperCarousel;