import { Box } from "@mui/material";
import NavbarButton from "./NavbarButton";

const BottomNavbar = (props) => {
  const { pages } = props;

  return (
    <Box
      sx={{
        display: {
          xs: "flex",
          sm: "flex",
          md: "none",
        },
        height: "max-content",
        width: "100%",
        padding: "0.5rem",
        backgroundColor: "var(--background-color1)",
        // backgroundColor: "rgb(var(--accent-color1))",
        justifyContent: "space-around",
        borderRadius: "1rem 1rem 0 0",
        zIndex: 10,
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px -10px 15px -3px, rgba(0, 0, 0, 0.05) 0px -4px 6px -2px",
      }}
    >
      {pages.map((page, index) => (
        <NavbarButton
          key={index}
          {...{
            icon: page.icon,
            title: page.title,
            img: page.img,
            href: page.href,
            ...props,
          }}
        />
      ))}
    </Box>
  );
};

export default BottomNavbar;
