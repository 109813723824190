import { Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import form_styles from "components/SpecificStyles/form.module.css";
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from "@mui/material/CircularProgress";
import CustomInput from "components/CustomInput";
import { handleLogout } from "utilities/helpers";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { post } from "utilities/requests";

const PrimeiroAcessoPage = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [is_loading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (password !== confirm_password) {
      setIsLoading(false);
      return toast.warning("As senhas não coincidem!");
    }

    if (password.length < 6 || password === "") {
      setIsLoading(false);
      console.log(password.length);
      return toast.warning("A senha deve ter no mínimo 6 caracteres!");
    }

    let form = new FormData();
    form.append("password", password);

    const res = await post("change_password_first_login", form);

    setIsLoading(false);

    if (res?.httpCode === 200) {
      toast.success("Senha alterada com sucesso!");
      localStorage.setItem("first_access", false)
      navigate("/admin/dashboard");
    } else {
      toast.error(res.message || "Erro ao fazer login. Contate o suporte.");
    }

    // if (res?.httpCode === 200) {
    //   localStorage.setItem("token", res.token);
    //   handleGetUserInfo().then((data) => {
    //     if (data.data.status === undefined || data.data.status === "") {
    //       navigate("/primeiro-acesso");
    //     } else {
    //       console.log("ueai");
    //       navigate("/admin/dashboard");
    //     }
    //   });
    // } else {
    //   toast.error(res.message || "Erro ao fazer login. Contate o suporte.");
    // }
    // setIsLoading(false);
  };

  useEffect(() => {
    toast.warn("Você precisa inserir nova senha para continuar!");
  }, []);

  return (
    <CustomSection
      style={{
        margin: "0 auto",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Primeiro acesso
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "30rem",
          }}
        >
          Para acessar o sistema, você inserir sua nova senha.
        </Typography>

        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
            width: "100%",
            maxWidth: "30rem",
          }}
          className={form_styles.customFormStyle}
          onSubmit={handleSubmit}
        >
          <CustomInput
            type={"password"}
            placeholder="Senha"
            icon={LockIcon}
            value={password}
            onChange={setPassword}
            // onClick={() => setShowPassword(!showPassword)}
          />
          <CustomInput
            type={"password"}
            placeholder="Confirmar senha"
            icon={LockIcon}
            value={confirm_password}
            onChange={setConfirmPassword}
            // onClick={() => setShowPassword2(!showPassword2)}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "1rem",
            }}
          >
            <Typography
              variant="p"
              sx={{
                fontSize: "1rem",
                // textAlign: "center",
                fontWeight: "normal",
              }}
              onClick={() => {
                handleLogout(dispatch);
              }}
            >
              Deseja sair? <a href="#">Clique aqui</a>.
            </Typography>
            {is_loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0.5rem 3rem",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <input
                type="submit"
                value="Alterar"
                style={{
                  maxWidth: "12rem",
                }}
              />
            )}
          </div>
        </form>
      </div>
    </CustomSection>
  );
};

export default PrimeiroAcessoPage;
