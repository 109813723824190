import { useEffect, useState } from "react";
import { get } from "utilities/requests";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingDots from "components/LoadingDots";
import CustomText from "components/CustomText";
import { Box, Container, Modal } from "@mui/material";
import OwnerProvaItem from "./Owner";
import QuestionProvaItem from "./Question";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

const ProvaView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [is_loading, setIsLoading] = useState(false);
  // const [data, setData] = useState({});
  // const [owner, setOwner] = useState({});
  // const [questions, setQuestions] = useState([]);

  const [data, setData] = useState({
    _id: 123,
    name: "Prova de matemática",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, natus.",
    created_at: "2021-10-10T00:00:00.000Z",
    owner: {
      _id: 12,
      name: "Joãozinho",
      role: "Professor",
    },
    tags: ["#Teclado", "#Matemática", "#Ensino Fundamental"],
    answers: [
      {
        _id: 1,
        question_content: "Qual a raiz quadrada de 4?",
        type: "objective",
        difficulty_level: "Fácil",
        degree: "Ensino Fundamental",
        justify_answer_text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, natus.",
        alternatives: [
          {
            alternative_content: "1",
          },
          {
            alternative_content: "2",
          },
          {
            alternative_content: "3",
          },
          {
            alternative_content: "4",
          },
        ],
        correct: "b",
      },
      {
        _id: 2,
        question_content: "Quem descobriu o Brasil?",
        question_answer: "Pedro Álvares Cabral",
        type: "text",
        difficulty_level: "Fácil",
        degree: "Ensino Fundamental",
        justify_answer_text:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, natus.",
      },
    ],
  });

  const [owner, setOwner] = useState({
    _id: 123,
    name: "Joãozinho",
    role: "Professor",
  });
  const [questions, setQuestions] = useState([
    {
      _id: 1,
      question_content: "Qual a raiz quadrada de 4?",
      type: "objective",
      difficulty_level: "Fácil",
      degree: "Ensino Fundamental",
      justify_answer_text:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, natus.",
      alternatives: [
        {
          alternative_content: "1",
        },
        {
          alternative_content: "2",
        },
        {
          alternative_content: "3",
        },
        {
          alternative_content: "4",
        },
      ],
      correct: "b",
    },
    {
      _id: 2,
      question_content: "Quem descobriu o Brasil?",
      question_answer: "Pedro Álvares Cabral",
      type: "text",
      difficulty_level: "Fácil",
      degree: "Ensino Fundamental",
      justify_answer_text:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, natus.",
    },
  ]);

  // const handleFetchExam = async (id) => {
  //   setIsLoading(true);
  //   const { data } = await get(`exams/${id}`);
  //   setIsLoading(false);
  //   return data;
  // };

  // useEffect(() => {
  //   const path_id = location.pathname.split("visualizar/")[1];
  //   if (data._id === undefined || data._id !== path_id) {
  //     // handleFetchExam(path_id).then((data) => {
  //     handleFetchExam("653c06de06162f29d08adc66").then((data) => {
  //       setData(data);
  //       setOwner(data.owner);
  //       setQuestions(data.questions);
  //     });
  //   }
  // }, [location]);

  return is_loading ? (
    <LoadingDots />
  ) : (
    <div
      style={{
        width: "100%",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        overflowY: "auto",
        paddingTop: "4rem",
      }}
    >
      <div
        style={{
          position: "fixed",
          top: "1rem",
          right: "1.6rem",
          gap: "1rem",
        }}
      >
        <HeaderButton
          text="Voltar"
          Icon={KeyboardBackspaceRoundedIcon}
          color="rgb(var(--accent-color1)"
          onClick={() => navigate("/admin/estudo-individual/")}
        />
        <HeaderButton
          text="Parar teste"
          Icon={PauseCircleOutlineIcon}
          color="rgb(var(--accent-color1)"
          navigate={() => navigate("/admin/estudo-individual/")}
        />
        <HeaderButton
          text="Cancelar teste"
          Icon={HighlightOffRoundedIcon}
          color="rgb(var(--accent-color0)"
          navigate={() => navigate("/admin/estudo-individual/")}
        />
      </div>

      <div
        style={{
          animation: "slideFromRight 1s",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        }}
      >
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <CustomText
            variant="h1"
            lines={1}
            style={{
              fontSize: "2.4rem",
              fontWeight: "bold",
            }}
            text={data?.name || "Nome da prova"}
          />
          <CustomText
            variant="p"
            style={{
              fontSize: "1.1rem",
              textAlign: "justify",
            }}
            text={data?.description || "Descrição da prova"}
          />

          <div>
            {data.tags.map((tag, index) => (
              <CustomText
                key={index}
                variant="span"
                text={tag}
                style={{
                  fontSize: "1rem",
                  backgroundColor: "var(--background-color2)",
                  padding: "0.2rem 0.4rem",
                  borderRadius: "0.4rem",
                  marginRight: "0.4rem",
                }}
              />
            ))}
          </div>

          <OwnerProvaItem {...{ owner, data }} />

          {/* <div>
            <span>#tags, #tags, #tags, #tags, </span>
          </div> */}

          {questions.map((question, index) => (
            <QuestionProvaItem key={index} {...{ question, index }} />
          ))}

          <div
            style={{
              height: "2rem",
            }}
          />
        </Container>
      </div>
    </div>
  );
};

export default ProvaView;

const HeaderButton = ({ text, Icon, color, onClick }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        backgroundColor: `${color}, 0.8)`,
        color: "var(--background-color1)",
        overflow: "hidden",
        // maxWidth: {
        //   xs: "20rem",
        //   sm: "3rem",
        // },
        gap: "0.5rem",
        alignItems: "center",
        padding: "0.5rem",
        transition: "all 800ms 2s ease",

        // "&:hover": {
        //   maxWidth: "20rem",
        //   transition: "all 1s ease",
        // },
      }}
      onClick={onClick}
    >
      <Icon
        sx={{
          width: "2rem",
          height: "2rem",
        }}
      />
      <span
        style={{
          whiteSpace: "nowrap",
        }}
      >
        {text}
      </span>
    </Box>
  );
};
