import { Tooltip } from "@mui/material";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import style from "./style.module.css";
import CustomText from "components/CustomText";

export default function DisciplineCard({ exam, onClick }) {
  return (
    <div
      className={`${style.swiperCard} Transition-hover`}
      style={{
        display: "flex",
        position: "relative",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "0rem",
          right: "0rem",
          cursor: "pointer",
          zIndex: 2,
        }}
      >
        <Tooltip title="Favoritar" placement="top">
          <FavoriteBorderRoundedIcon
            sx={{
              color: "#e31b23",
              WebkitFilter: `drop-shadow(4px 4px 0 ${exam.bg_color}) drop-shadow(-2px 2px 0 ${exam.bg_color}) drop-shadow(2px -2px 0 ${exam.bg_color}) drop-shadow(-2px -2px 0 ${exam.bg_color})`,
              filter: `drop-shadow(4px 4px 0 ${exam.bg_color}) drop-shadow(-2px 2px 0 ${exam.bg_color}) drop-shadow(4px -4px 0 ${exam.bg_color}) drop-shadow(-2px -2px 0 ${exam.bg_color})`,
            }}
            onClick={() => console.log("Favoritar")}
          />
        </Tooltip>
      </div>
      <div
        style={{
          flexDirection: "column",
          width: "100%",
          padding: "0.6rem",
          borderRadius: "0.8rem",
          position: "relative",
          background: `linear-gradient(222deg, ${exam.bg_color} 0%, var(--background-color1) 100%)`,
        }}
        onClick={onClick}
      >
        <img
          src={exam.img}
          alt={exam.name}
          style={{
            width: "100%",
            height: "12rem",
            objectFit: "cover",
            borderRadius: "0.4rem",
            marginBottom: "0.6rem",
          }}
        />

        <CustomText
          variant={"p"}
          text={exam.name}
          lines={2}
          style={{
            fontSize: "1rem",
            color: exam.text_color,
            fontWeight: 600,
          }}
        />

        <CustomText
          variant={"p"}
          text={exam.institution}
          lines={2}
          style={{
            fontSize: "0.8rem",
            color: exam.text_color,
            opacity: 0.8,
          }}
        />
      </div>
    </div>
  );
}
