import CustomSection from "components/CustomSection";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Typography } from "@mui/material";

export default function SubPlans() {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        alignItems: "center",
      }}
      style_child
      bg_color="var(--background-color0)"
      id="planos"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          gap: "3rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          Comece Sua Jornada de Aprendizado
        </Typography>
        
        <div
          style={{
            width: "100%",
            height: "max-content",
            display: "flex",
            gap: "2rem",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "2rem",
          }}
        >
          <PlanCard
            title="Plano Gratuito"
            price="0,00"
            subtitle="Para experimentar a plataforma e não precisa de muitos recursos para começar."
            features={[
              "Até 5 alunos",
              "Até 5 aulas",
              "Até 5 exercícios",
              "Até 5 provas",
            ]}
          />
          <PlanCard
            title="Plano Gratuito"
            price="0,00"
            subtitle="Para experimentar a plataforma e não precisa de muitos recursos para começar."
            features={[
              "Até 5 alunos",
              "Até 5 aulas",
              "Até 5 exercícios",
              "Até 5 provas",
              "Até 5 simulados",
              "Até 5 questões",
            ]}
            popular
          />
          <PlanCard
            title="Plano Gratuito"
            price="0,00"
            subtitle="Para experimentar a plataforma e não precisa de muitos recursos para começar."
            features={[
              "Até 5 alunos",
              "Até 5 aulas",
              "Até 5 exercícios",
              "Até 5 provas",
              "Até 5 simulados",
              "Até 5 questões",
              "Até 5 vídeos",
              "Até 5 arquivos",
            ]}
          />
        </div>
      </div>
    </CustomSection>
  );
}

const PlanCard = ({ title, subtitle, price, features, popular }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
        flexBasis: "27rem",
        maxWidth: "27rem",
        flexGrow: 1,
        background: popular
          ? "linear-gradient(20deg, rgba(var(--accent-color1), 0.4) 0%, rgba(var(--accent-color1), 0.7) 80%)"
          : "linear-gradient(20deg, rgba(var(--accent-color1), 0.2) 0%, var(--background-color1) 80%)",
        color: popular ? "white" : "var(--text-color0)",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        padding: "2rem",
        borderRadius: "1.6rem",
        position: "relative",
      }}
    >
      {popular && (
        <div
          style={{
            position: "absolute",
            top: "0.6rem",
            right: "0.6rem",
            padding: "0.4rem 0.8rem",
            borderRadius: "10rem",
            backgroundColor: "rgba(255, 255, 255, 0.16)",
            color: "white",
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          Mais popular
        </div>
      )}
      <h3>{title}</h3>

      <div
        style={{
          display: "flex",
          gap: "0.4rem",
          alignItems: "center",
        }}
      >
        <b
          style={{
            fontSize: "2rem",
          }}
        >
          R$ {price}
        </b>
        <span
          style={{
            marginTop: "0.4rem",
            fontSize: "0.9rem",
          }}
        >
          /mês
        </span>
      </div>

      <p>{subtitle}</p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.4rem",
          height: "100%",
        }}
      >
        {features.map((feature) => (
          <div
            style={{
              display: "flex",
              gap: "0.6rem",
              alignItems: "center",
            }}
          >
            <CheckIcon />

            <span>{feature}</span>
          </div>
        ))}
      </div>

      <div>
        <button
          style={{
            width: "100%",
            height: "3rem",
            borderRadius: "10rem",
            backgroundColor: popular
              ? "white"
              : "rgba(var(--accent-color1), 0.5)",
            color: popular
              ? "rgba(var(--accent-color1), 0.7)"
              : "var(--background-color0)",
            fontSize: "1.1rem",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
          }}
        >
          Assinar
        </button>
      </div>
    </div>
  );
};
