import { Box } from "@mui/material";
import AplicacoesTableSection from "../Table";
import AplicacoesCardsSection from "../Cards";
import { useEffect, useState } from "react";
import { get } from "utilities/requests";

export default function AplicacoesItems({ applications }) {
  const [server_time, setServerTime] = useState(null);

  const changeApplicationStatus = () => {
    get("student_application/get-current-time").then((res) => {
      if (res.status === "success") {
        setServerTime(new Date(res.time));
      }
    });
  };

  useEffect(() => {
    changeApplicationStatus();
    setInterval(() => {
      changeApplicationStatus();
    }, 30000);
  }, []);
  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "block",
          },
          overflowY: "auto",
        }}
      >
        <AplicacoesTableSection
          {...{ applications, server_time, setServerTime }}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
          },
        }}
      >
        <AplicacoesCardsSection
          {...{ applications, server_time, setServerTime }}
        />
      </Box>
    </>
  );
}
