import CustomText from "components/CustomText";
import { maskDifficulty } from "utilities/exams/maskDifficulty";
import SchoolIcon from "@mui/icons-material/School";
import { Box, Icon, Radio } from "@mui/material";
import { useState } from "react";
import form_styles from "components/SpecificStyles/form.module.css";

const QuestionProvaItem = ({ question, index }) => {
  const difficulty_style = maskDifficulty(question.difficulty_level);

  const [selectedValue, setSelectedValue] = useState("0");

  const handleChange = (event) => {
    setSelectedValue(parseInt(event.target.value));
  };

  return (
    <Box
      sx={{
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "var(--background-color1)",
        padding: {
          xs: "0.6rem 0.8rem",
          sm: "1rem 1.4rem",
          md: "1.6rem 2rem",
        },
      }}
    >
      <CustomText
        variant="span"
        text={`${index + 1}. <b style="font-weight: 600;">${
          question.question_content
        }</b>`}
        style={{
          fontSize: "1.1rem",
          textAlign: "justify",
          width: "100%",
        }}
      />

      <div
        style={{
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {question.type === "objective" &&
          question.alternatives.map((alternative, index) => (
            <div
              key={index}
              style={{
                flexDirection: "row",
                gap: "1rem",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Radio
                checked={selectedValue === index}
                onChange={handleChange}
                value={index}
                name="radio-buttons"
                inputProps={{ "aria-label": index }}
              />

              <CustomText
                variant="span"
                text={alternative.alternative_content}
                style={{
                  width: "100%",
                  fontSize: "1rem",
                  textAlign: "justify",
                }}
              />
            </div>
          ))}

        {question.type === "text" && (
          <CustomText
            variant="span"
            text="Texto da questao aqui"
            style={{
              width: "100%",
              fontSize: "1rem",
              textAlign: "justify",
            }}
          />
        )}

        <div
          style={{
            padding: "0.4rem 0.8rem",
            backgroundColor: "var(--background-color2)",
            borderRadius: "0.4rem",
            gap: "0.6rem",
            alignItems: "center",
          }}
        >
          <CustomText
            text={`Dificuldade:`}
            style={{
              fontSize: "1rem",
              textAlign: "justify",
              fontWeight: 600,
            }}
          />
          <div
            style={{
              gap: "0.3rem",
              alignItems: "center",
            }}
          >
            <Icon
              component={difficulty_style.dif_icon}
              style={{
                color: difficulty_style.dif_color,
                fontSize: "1.8rem",
                backgroundColor: "white",
                borderRadius: "50%",
              }}
            />
            <CustomText
              text={question.difficulty_level}
              style={{
                fontSize: "1rem",
                textAlign: "justify",
              }}
            />
          </div>
          <div
            style={{
              gap: "0.3rem",
              alignItems: "center",
            }}
          >
            <SchoolIcon
              style={{
                fontSize: "1.8rem",
              }}
            />
            <CustomText
              text={question.degree}
              style={{
                fontSize: "1rem",
                textAlign: "justify",
              }}
            />
          </div>
        </div>
      </div>
    </Box>
  );
};

export default QuestionProvaItem;
