import CustomText from "components/CustomText";
import { indexToLetter, maskDifficulty } from "utilities/exams/maskDifficulty";
import { Box, Icon, Radio } from "@mui/material";
import { useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";

const QuestionProvaItem = ({
  question,
  question_index,
  handleSaveAnswer,
  all_questions,
  setAllQuestions,
}) => {
  const [debounce_id, setDebounceId] = useState(null);
  const [saving, setSaving] = useState(null);
  const difficulty_style = maskDifficulty(question.difficulty_level);

  const handleEssayDebounce = (question, essay_answer) => {
    // insert essay_answer inside question
    let new_questions = [...all_questions];
    new_questions[question_index].essay_answer = essay_answer;
    setAllQuestions(new_questions);

    setSaving(true);
    clearTimeout(debounce_id);
    let id = setTimeout(() => {
      setSaving(false);
      handleSaveAnswer(question, null, essay_answer);
    }, 5000);
    setDebounceId(id);
  };

  return (
    <div
      style={{
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      {question.question_father && (
        <Box
          sx={{
            flexDirection: "column",
            gap: "1rem",
            backgroundColor: "var(--background-color1)",
            width: "100%",
            padding: {
              xs: "0.6rem 0.8rem",
              sm: "1rem 1.4rem",
              md: "1.6rem 2rem",
            },
          }}
        >
          <CustomText
            variant="span"
            text={"Leia o enunciado e responda"}
            style={{
              fontSize: "1.4rem",
              fontWeight: 700,
              textAlign: "justify",
              width: "100%",
            }}
          />
          <CustomText
            variant="span"
            text={question.question_father.question_content}
            style={{
              fontSize: "1.2rem",
              textAlign: "justify",
              width: "100%",
            }}
          />
        </Box>
      )}
      <Box
        sx={{
          flexDirection: "column",
          gap: "1rem",
          backgroundColor: "var(--background-color1)",
          width: "100%",
          padding: {
            xs: "0.6rem 0.8rem",
            sm: "1rem 1.4rem",
            md: "1.6rem 2rem",
          },
        }}
      >
        <CustomText
          variant="span"
          text={`${question_index + 1}. <b style="font-weight: 600;">${
            question.question_content
          }</b>`}
          style={{
            fontSize: "1.4rem",
            textAlign: "justify",
            width: "100%",
          }}
        />

        <div
          style={{
            flexDirection: "column",
            gap: "0.4rem",
          }}
        >
          {question.type === "objective" &&
            question.alternatives.map((alternative, index) => (
              <Box
                key={index}
                sx={{
                  flexDirection: "row",
                  gap: "1rem",
                  width: "100%",
                  alignItems: "center",
                  cursor: "pointer",
                  padding: "0.4rem 0rem",
                  backgroundColor: alternative.checked
                    ? "var(--background-color2)"
                    : "var(--background-color1)",

                  transition: "all 0.2s ease-in-out",

                  "&:hover": {
                    backgroundColor: "var(--background-color2)",
                  },
                }}
                onClick={() => {
                  handleSaveAnswer(question, alternative);
                }}
              >
                <div>
                  {alternative.checked ? (
                    <CheckCircleRoundedIcon
                      sx={{
                        color: "rgba(var(--accent-color1), 0.6)",
                        fontSize: "1.8rem",
                      }}
                    />
                  ) : (
                    <RadioButtonUncheckedRoundedIcon
                      sx={{
                        fontSize: "1.8rem",
                      }}
                    />
                  )}
                </div>

                <CustomText
                  variant="span"
                  text={`<b>${indexToLetter(index)}) </b>${
                    alternative.alternative_content
                  }`}
                  style={{
                    width: "100%",
                    fontSize: "1.1rem",
                    textAlign: "justify",
                  }}
                />
              </Box>
            ))}

          {question.type === "text" && (
            <div
              style={{
                flexDirection: "column",
                gap: "0.4rem",
              }}
            >
              <textarea
                style={{
                  backgroundColor: "var(--background-color2)",
                  borderRadius: "0.5rem",
                  padding: "1rem",
                  border: "none",
                  fontFamily: "var(--Poppins)",
                  resize: "vertical",
                  fontSize: "1.1rem",
                }}
                placeholder="Explique o motivo da revisão. Min. 10 caracteres."
                value={question.essay_answer}
                onInput={(e) => handleEssayDebounce(question, e.target.value)}
              />
              <b>
                {saving === null
                  ? "A resposta será salva automaticamente."
                  : saving
                  ? "Salvando..."
                  : "Resposta salva."}
              </b>
            </div>
          )}

          {/* <div
            style={{
              padding: "0.4rem 0.8rem",
              backgroundColor: "var(--background-color2)",
              borderRadius: "0.4rem",
              gap: "0.6rem",
              alignItems: "center",
            }}
          >
            <CustomText
              text={`Dificuldade:`}
              style={{
                fontSize: "1rem",
                textAlign: "justify",
                fontWeight: 600,
              }}
            />
            <div
              style={{
                gap: "0.3rem",
                alignItems: "center",
              }}
            >
              <Icon
                component={difficulty_style.dif_icon}
                style={{
                  color: difficulty_style.dif_color,
                  fontSize: "1.8rem",
                  backgroundColor: "white",
                  borderRadius: "50%",
                }}
              />
              <CustomText
                text={question.difficulty_level}
                style={{
                  fontSize: "1rem",
                  textAlign: "justify",
                }}
              />
            </div>
            <div
              style={{
                gap: "0.3rem",
                alignItems: "center",
              }}
            >
              <SchoolIcon
                style={{
                  fontSize: "1.8rem",
                }}
              />
              <CustomText
                text={question.degree}
                style={{
                  fontSize: "1rem",
                  textAlign: "justify",
                }}
              />
            </div>
          </div> */}
        </div>
      </Box>
    </div>
  );
};

export default QuestionProvaItem;
