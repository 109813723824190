import { Box, Icon } from "@mui/material";
import CustomText from "components/CustomText";
import { useEffect, useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";

export default function AplicacoesResponderExamsSection({
  exams,
  selected_exam,
  setSelectedExam,
}) {
  return (
    <div
      style={{
        flexDirection: "column",
        gap: "0.2rem",
        width: "100%",
      }}
    >
      <CustomText
        text={`Selecionar prova`}
        variant={"p"}
        style={{
          fontSize: {
            xs: "1.2rem",
            sm: "1.4rem",
            md: "1.8rem",
          },
          fontWeight: 700,
          width: "100%",
        }}
      />
      <p>Provas da aplicação:</p>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          width: "100%",
        }}
      >
        {exams.map((exam, index) => (
          <ExamItem key={index} {...{ exam, selected_exam, setSelectedExam }} />
        ))}
      </div>
    </div>
  );
}

function ExamItem({ exam, selected_exam, setSelectedExam }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selected_exam?._id === exam._id) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [selected_exam]);

  return (
    <Box
      sx={{
        background: checked
          ? "rgba(var(--accent-color1), 0.7)"
          : "linear-gradient(45deg, var(--background-color2) 0%, var(--background-color0) 100%)",
        gap: "1rem",
        border: "1px solid var(--background-color2)",
        backgroundSize: "200% 100%",
        backgroundPosition: checked ? "left" : "right",
        padding: "0.6rem 1.2rem",
        cursor: "pointer",
        color: checked ? "var(--background-color0)" : "var(--text-color2)",
        borderRadius: "1rem",
        alignItems: "center",
        transition: "0.3s ease",
        position: "relative",
        "&:hover": {
          backgroundPosition: "left",
        },
      }}
      onClick={() => {
        setSelectedExam(exam);
      }}
    >
      <div>
        {checked ? (
          <CheckCircleRoundedIcon />
        ) : (
          <RadioButtonUncheckedRoundedIcon />
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingRight: "0.6rem",
        }}
      >
        <span
          style={{
            fontSize: "1.1rem",
            fontWeight: 700,
          }}
        >
          {exam.name}
        </span>
        <span
          style={{
            fontSize: "0.9rem",
          }}
        >
          {exam.description}
        </span>
      </div>
    </Box>
  );
}
